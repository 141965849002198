import * as FromSearch from 'reducers/search/selectors';

export const SLICE = 'search';

export const getFilter = (id, group = false) => state =>
  FromSearch.getFilter(state[SLICE], id, group);
export const getFiltersByGroupId = group => state =>
  FromSearch.getFiltersByGroupId(state[SLICE], group);
export const getHasResults = state => FromSearch.getHasResults(state[SLICE]);
export const getHasValidQuery = state =>
  FromSearch.getHasValidQuery(state[SLICE]);
export const getListsRequest = state =>
  FromSearch.getListsRequest(state[SLICE]);
export const getSearchRequest = state =>
  FromSearch.getSearchRequest(state[SLICE]);
export const getHasFilters = keys => state =>
  FromSearch.getHasFilters(state[SLICE], keys);
export const getActiveFilters = keys => state =>
  FromSearch.getActiveFilters(state[SLICE], keys);
export const getShowRefreshDialog = state =>
  FromSearch.getShowRefreshDialog(state[SLICE]);
export const getNextResultsForRefresh = state =>
  FromSearch.getNextResultsForRefresh(state[SLICE]);
export const getExternalSearch = state =>
  FromSearch.getExternalSearch(state[SLICE]);
export const getLastSuccessfulSearch = state =>
  FromSearch.getLastSuccessfulSearch(state[SLICE]);
export const getSortBy = state => FromSearch.getSortBy(state[SLICE]);
export const getOrgSortBy = state => FromSearch.getOrgSortBy(state[SLICE]);
export const getSearchView = state => FromSearch.getSearchView(state[SLICE]);
export const getFilters = state => FromSearch.getFilters(state[SLICE]);
export const getRestoringState = state =>
  FromSearch.getRestoringState(state[SLICE]);
export const getShowBuilder = state => FromSearch.getShowBuilder(state[SLICE]);
export const getSearchBuilderFields = id => state =>
  FromSearch.getSearchBuilderFields(state[SLICE], id);

export const getResearcherIds = state =>
  FromSearch.getResearcherIds(state[SLICE]);

export const getListAssignedToResearcher = state =>
  FromSearch.getListAssignedToResearcher(state[SLICE]);

export const getListAssignedToResearcherAvailable = state =>
  FromSearch.getListAssignedToResearcherAvailable(state[SLICE]);

export const getOrganisationIds = state =>
  FromSearch.getOrganisationIds(state[SLICE]);
export const getListAssignedToOrganisation = state =>
  FromSearch.getListAssignedToOrganisation(state[SLICE]);

export const getBlockedForExport = state =>
  FromSearch.getBlockedForExport(state[SLICE]);

export const getMapRegion = state => FromSearch.getMapRegion(state[SLICE]);

export const getGeoSearchData = state =>
  FromSearch.getGeoSearchData(state[SLICE]);

export const isPdfExportRunning = state =>
  FromSearch.isPdfExportRunning(state[SLICE]);

export const getFilterSectionIsCollapsed = sectionKey => state =>
  FromSearch.getFilterSectionIsCollapsed(state[SLICE], sectionKey);

export const hasDissmissedVCFundingSortModal = state =>
  FromSearch.hasDissmissedVCFundingSortModal(state[SLICE]);

export const getShowVCFundingSortModal = state =>
  FromSearch.getShowVCFundingSortModal(state[SLICE]);

export const getAddMembersToListFromSearchError = state =>
  FromSearch.getAddMembersToListFromSearchError(state[SLICE]);
