import { Switch } from "components/common";
import { usePut } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userSettings } from "services/api";
import { getUserSettingsFromUrl } from "../userSettingsType";
import { setUserSetting } from "actions/userSettings";

export default function LicenseCheckBox({ licenseName, value }) {

    const [localChecked, setLocalChecked] = useState(false);

    const [{ res, loading, error }, putUserSetting] = usePut({
        url: userSettings,
    });

    const fetchUrl = getUserSettingsFromUrl[licenseName];
    const dispatch = useDispatch();

    useEffect(() => {
        if (res?.status === 'OK') {
            setLocalChecked(a => !a);
        }
    }, [res, error]);

    useEffect(() => {
        setLocalChecked(value);
    }, [value]);

    const handleCheckboxChecked = () => {
        if (fetchUrl) {
            putUserSetting({
                userSettingType: fetchUrl,
                value: !localChecked,

            });
        }
        else {
            dispatch(setUserSetting(licenseName, !localChecked));
            setLocalChecked(a => !a);
        }
    }


    return (
        <Switch
            disabled={loading}
            size='small'
            onChange={handleCheckboxChecked}
            checked={localChecked}
            label={localChecked ? 'Enabled' : 'Disabled'}
            reverseLabel
        />
    );
}