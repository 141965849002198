import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from 'hooks';

import { getActiveFilters, getFilter } from 'selectors/search';
import { lists, organisationLists } from 'services/inputTokens';
import licenseType from 'models/licenseType';

import { Filter } from '../helpers';
import FilterGroup from './FilterGroup';
import { listsKeys } from './keys';
import { FilterSection } from './styled';

const Lists = () => {
  const { hasLicense } = useAuth();
  const hasOrgFilters = useMemo(
    () => hasLicense([licenseType.industry], true),
    [hasLicense]
  );

  const activeFilters = useSelector(getActiveFilters(Object.keys(listsKeys)));
  const listsFilter = useSelector(getFilter('lists'));
  const excludeListsFilter = useSelector(getFilter('excludeLists'));
  const organisationListsFilter = useSelector(getFilter('organisationLists'));
  const organisationExcludeListsFilter = useSelector(
    getFilter('organisationExcludeLists')
  );

  return (
    <FilterGroup
      icon='list'
      title='Lists'
      activeKeys={activeFilters}
      sectionKey='lists'
    >
      <>
        <FilterSection style={{ borderTop: 'none' }}>
          Researcher Lists
        </FilterSection>
        <Filter
          filterType='token'
          filterId={listsKeys.lists.key}
          placeholder='Included'
          optionKey='id'
          displayKey='name'
          options={lists}
          onTheFly={false}
          helpText='Results will be limited to researchers present in these lists'
          otherRemoteParams={{
            excludeIds: listsFilter?.map(x => x?.id)?.filter(x => x) ?? [],
          }}
        />
        <Filter
          filterType='token'
          filterId={listsKeys.excludeLists.key}
          placeholder='Excluded'
          optionKey='id'
          displayKey='name'
          options={lists}
          onTheFly={false}
          helpText='Researchers present in these lists will be excluded from results'
          otherRemoteParams={{
            excludeIds:
              excludeListsFilter?.map(x => x?.id)?.filter(x => x) ?? [],
          }}
        />
        {hasOrgFilters && (
          <>
            <FilterSection>Organisation Lists</FilterSection>
            <Filter
              filterType='token'
              filterId={listsKeys.organisationLists.key}
              placeholder='Included'
              optionKey='id'
              displayKey='name'
              options={organisationLists}
              onTheFly={false}
              helpText='Results will be limited to organisations present in these lists'
              otherRemoteParams={{
                excludeIds:
                  organisationListsFilter?.map(x => x?.id)?.filter(x => x) ??
                  [],
              }}
            />
            <Filter
              filterType='token'
              filterId={listsKeys.organisationExcludeLists.key}
              placeholder='Excluded'
              optionKey='id'
              displayKey='name'
              options={organisationLists}
              onTheFly={false}
              helpText='Organisations present in these lists will be excluded from results'
              otherRemoteParams={{
                excludeIds:
                  organisationExcludeListsFilter
                    ?.map(x => x?.id)
                    ?.filter(x => x) ?? [],
              }}
            />
          </>
        )}
      </>
    </FilterGroup>
  );
};

export default Lists;
