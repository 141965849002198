import { useDispatch, useSelector } from 'react-redux';

import { countries, states, timeAtCompany } from 'services/inputTokens';
import styled from 'styled-components';
import OrgFilterField from './OrgFilterField';
import {
  resetOrgWorkItemFilters,
  setOrgWorkItemFilterGroups,
} from 'actions/organisation';
import { Button } from 'components/common';
import { getOrganisationWorkItemFilter } from 'selectors/organisation';

const Content = styled.div`
  padding: 0px 0px 10px;
  > * {
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 5px;
    }
  }
`;

export const Actions = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const OrganisationWorkItemFilters = ({
  organisationId,
  onFilterUpdate,
}) => {
  const dispatch = useDispatch();

  const handleSearch = () => {
    onFilterUpdate();
  };

  const handleReset = () => {
    dispatch(resetOrgWorkItemFilters(organisationId));
  };

  const statesFilter = useSelector(
    getOrganisationWorkItemFilter(organisationId, 'state')
  );
  return (
    <>
      <Content>
        <OrgFilterField
          filterType='token'
          filterId='country'
          organisationId={organisationId}
          placeholder='Region/Country'
          options={countries}
          setFilterGroup={setOrgWorkItemFilterGroups}
          getFilter={getOrganisationWorkItemFilter}
        />
        <OrgFilterField
          filterType='token'
          placeholder='Cities'
          filterId='city'
          organisationId={organisationId}
          setFilterGroup={setOrgWorkItemFilterGroups}
          getFilter={getOrganisationWorkItemFilter}
        />
        <OrgFilterField
          filterType='token'
          placeholder='States'
          options={states}
          filterId='state'
          organisationId={organisationId}
          setFilterGroup={setOrgWorkItemFilterGroups}
          getFilter={getOrganisationWorkItemFilter}
          displayKey={'displayName'}
          onTheFly={false}
          otherRemoteParams={{
            excludeStateIds:
              statesFilter?.map(x => x?.id)?.filter(x => x) ?? [],
          }}
        />
        <OrgFilterField
          filterType='token'
          filterId='jobTitle'
          placeholder='Job Title'
          organisationId={organisationId}
          setFilterGroup={setOrgWorkItemFilterGroups}
          getFilter={getOrganisationWorkItemFilter}
        />
        <OrgFilterField
          filterType='token'
          filterId='lastName'
          placeholder='Last Name'
          organisationId={organisationId}
          setFilterGroup={setOrgWorkItemFilterGroups}
          getFilter={getOrganisationWorkItemFilter}
        />
        <OrgFilterField
          filterType='token'
          options={timeAtCompany}
          filterId='timeAtCompany'
          placeholder='Time at Company'
          organisationId={organisationId}
          setFilterGroup={setOrgWorkItemFilterGroups}
          getFilter={getOrganisationWorkItemFilter}
        />
        <OrgFilterField
          filterType='checkbox'
          checkValue={organisationId}
          filterId='sponsorSuperOrgId'
          organisationId={organisationId}
          setFilterGroup={setOrgWorkItemFilterGroups}
          getFilter={getOrganisationWorkItemFilter}
        >
          Clinical Trial Sponsor
        </OrgFilterField>
      </Content>
      <Actions>
        <Button data-testid='filter-reset' onClick={handleReset}>
          Reset
        </Button>
        <Button
          onClick={handleSearch}
          type='primary'
          style={{ marginLeft: '10px' }}
          data-testid='filter-update'
        >
          Update
        </Button>
      </Actions>
    </>
  );
};
