import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth } from 'hooks';
import licenseType, { licenseTypeToStringMap } from 'models/licenseType';
import { getSearchView } from 'selectors/search';
import { format } from 'utils/date';
import styled from 'styled-components';
import Icon from './Icon';
import { ResearcherSearch } from 'components/Search/Results/helpers';

const Container = styled.div`
  margin-left: 10px;
  position: relative;
  min-width: 400px;
  .license-container {
    position: absolute;
    left: 0px;
    top: -8px;
    overflow: hidden;
    border-radius: 5px;
    background-color: #faf9f6;
    color: #8c8c8c;
    padding: 4px 10px;
    font-size: 14px;
    line-height: 20px;
  }
  .license-container_expiring-soon {
    box-shadow: 0px 3px 6px #00000029;
    color: #353535;
  }
  .license-container_expiration-imminent {
    box-shadow: 0px 3px 6px #00000029;
    color: #e83737;
    font-weight: 500;
  }
  .license-container__status {
    background-color: #faf9f6;
    border-radius: 5px;
  }
  svg {
    margin-right: 10px;
    width: 16px !important;
  }
`;

const searchPath = '/';
const DemoMode = () => {
  const { user } = useAuth();
  const searchView = useSelector(getSearchView);
  const { pathname } = useLocation();
  const isSearchRoute = pathname === searchPath;
  if (!user.licenses?.some(x => x.demoMode)) return null;

  const oldDemoLicense = user.licenses.find(
    x => x.type === licenseType.temporaryOld && x.demoMode
  );

  let license;
  // show trial indication only on search page for all licenses except temporary old
  if (!isSearchRoute && !oldDemoLicense) {
    return null;
  }

  if (oldDemoLicense && (!isSearchRoute || ResearcherSearch(searchView))) {
    license = oldDemoLicense;
  } else if (!ResearcherSearch(searchView)) {
    license = user.licenses.find(
      x => x.type === licenseType.industry && x.demoMode
    );

    if (!license) {
      license = user.licenses.find(
        x =>
          (x.type === licenseType.legacy || x.type === licenseType.academic) &&
          x.demoMode
      );
    }
  } else {
    license = user.licenses.find(
      x =>
        (x.type === licenseType.legacy || x.type === licenseType.academic) &&
        x.demoMode
    );

    if (!license) {
      license = user.licenses.find(
        x => x.type === licenseType.industry && x.demoMode
      );
    }
  }

  if (!license) return null;

  const daysUntilDemoExpires =
    (new Date(license.expiration) - Date.now()) / (1000 * 3600 * 24);

  let statusClassName;
  let statusIcon;
  let color;

  if (daysUntilDemoExpires < 1) {
    statusClassName = 'license-container_expiration-imminent';
    statusIcon = 'warning';
    color = '#E83737';
  } else if (daysUntilDemoExpires < 3) {
    statusClassName = 'license-container_expiring-soon';
    statusIcon = 'clock';
    color = '#FFBA00';
  } else {
    statusClassName = '';
    statusIcon = 'check';
    color = '#74D893';
  }

  return (
    <Container>
      <div className={`license-container ${statusClassName}`}>
        <div className='license-container__status'>
          <Icon icon={statusIcon} color={color} size='1x' />
          Trial License Status: Ends{' '}
          {format(license.expiration, 'MMMM DD YYYY')}
        </div>
        <div className='license-container__type'>
          <Icon icon='category' color={color} size='1x' />
          License Type:{' '}
          {user.licenses
            .filter(x => x.demoMode)
            .map(x => licenseTypeToStringMap[x.type])
            .join(' & ')}
        </div>
      </div>
    </Container>
  );
};

export default DemoMode;
