import { useSelector } from 'react-redux';

import { getSearchView } from 'selectors/search';

import Visualisations from './Visualisations';
import { Researcher, Organisation } from './Result';
import { ResultsContainer } from './styled';
import Map from './Map';
import { view as viewConstants } from './constants';
import { IsMap } from './helpers';

const List = ({ data, loading, onMapError, ...rest }) => {
  const view = useSelector(getSearchView);

  if (!data.groupedResults) return null;

  if (
    view !== viewConstants.researcher &&
    view !== viewConstants.organisation &&
    loading
  )
    return null;

  if (
    view === viewConstants.visualisation ||
    view === viewConstants.organisationInsights
  ) {
    return <Visualisations />;
  }

  if (IsMap(view)) {
    return <Map onError={onMapError} />;
  }

  const Result = view === viewConstants.researcher ? Researcher : Organisation;

  const memberIdKey =
    view === viewConstants.researcher ? 'researcherId' : 'superOrganisationId';
  const { listGroupByMembers } = rest;

  return (
    <ResultsContainer>
      {data.groupedResults.map((r, i) => (
        <Result
          data-testid={`search-result-${i}`}
          key={`${r.researcherId}-${i}`}
          listAssociatedByMember={
            listGroupByMembers ? listGroupByMembers[r[memberIdKey]] : []
          }
          memberId={r[memberIdKey]}
          {...rest}
          {...r}
        />
      ))}
    </ResultsContainer>
  );
};

export default List;
