import { useState } from 'react';
import { Tag } from 'antd';

import { TabbedPanel, CollapsiblePanel, Avatar } from 'components/common';
import { useWindowWidth } from 'hooks';

import Profile from './Profile';
import Actions from './Actions';
import Stats from './Stats';
import {
  Container,
  ActionBar,
  TopContent,
  ProfileContainer,
  StatsContainer,
} from '../styled';
import { BuildConfig } from './helpers';

const Result = ({ 'data-testid': dataTestId, ...rest }) => {
  const { isMobile } = useWindowWidth();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [activeTab, setActiveTab] = useState();

  const config = BuildConfig(rest);

  const toggleCollapse = () => {
    setActiveTab();
    setIsCollapsed(s => !s);
  };

  const handleStatClick = (tab, e) => {
    e && e.stopPropagation();
    if (!config[tab] || tab === activeTab) {
      setActiveTab(null);
      return setIsCollapsed(true);
    }
    if (isCollapsed) setIsCollapsed(false);
    setActiveTab(tab);
  };

  return (
    <Container data-testid={dataTestId}>
      <TopContent onClick={toggleCollapse}>
        <ProfileContainer>
          {!isMobile && <Avatar />}
          <Profile {...rest} />
        </ProfileContainer>
        <StatsContainer>
          <Stats {...rest} onStatClick={handleStatClick} />
        </StatsContainer>
      </TopContent>
      <ActionBar>
        <Actions {...rest} />
        {rest.totalMatches > 0 && (
          <Tag>Last matched activity {rest.mostRecentDate}</Tag>
        )}
      </ActionBar>
      <CollapsiblePanel
        isCollapsed={isCollapsed}
        style={{ background: 'white' }}
      >
        <TabbedPanel
          config={config}
          activeTab={activeTab}
          onActiveTabChange={setActiveTab}
        />
      </CollapsiblePanel>
    </Container>
  );
};

export default Result;
