import { useCallback, useEffect } from 'react';

import useXHR from './useXHR';

const usePatch = ({ url, body, onMount }) => {
  const [state, req, setRes] = useXHR(onMount);
  const requestText = JSON.stringify(body);

  const send = useCallback(
    bodyOrConfig => {
      let latestUrl = url;
      let latestBody = requestText;

      if (bodyOrConfig && !bodyOrConfig.url && !bodyOrConfig.body) {
        latestBody = JSON.stringify(bodyOrConfig);
      }

      if (bodyOrConfig?.url) {
        latestUrl = bodyOrConfig.url;
      }

      if (bodyOrConfig?.body) {
        latestBody = JSON.stringify(bodyOrConfig.body);
      }

      req.open('PATCH', latestUrl);
      req.setRequestHeader('Content-Type', 'application/json');
      req.send(latestBody);
    },
    [req, url, requestText]
  );

  useEffect(() => {
    if (onMount) {
      send();
    }
    return () => req.abort();
  }, [onMount, send, req]);

  return [state, send, setRes];
};

export default usePatch;
