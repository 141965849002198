import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colours } from 'utils/theme';

import Tooltip from '../Tooltip';
import Icon from '../Icon';

const isOnlyIndustryOrganisation = group =>
  group?.length === 1 && group[0].toLowerCase() === 'industry';
const isAcademicEmail = email => {
  if (!email) {
    return false;
  }

  if (!email.includes('@')) {
    return false;
  }

  const emailParts = email.split('@');
  const domain = emailParts[emailParts.length - 1];
  return domain.includes('.edu') || domain.includes('.ac');
};

const Container = styled.div`
  cursor: 'pointer';
  margin-left: 5px;
`;

const EmailExplanation = ({ email, organisationCategoryGroup }) => {
  if (
    isOnlyIndustryOrganisation(organisationCategoryGroup) &&
    isAcademicEmail(email)
  ) {
    return (
      <Tooltip
        title='This academic contact has a relationship with this industry account and is valid.'
        placement='right'
      >
        <Container style={{ cursor: 'pointer' }}>
          <Icon icon='help' color={colours.teal} size='1x' />
        </Container>
      </Tooltip>
    );
  }

  return null;
};

EmailExplanation.propTypes = {
  email: PropTypes.string.isRequired,
  organisationCategoryGroup: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EmailExplanation;
