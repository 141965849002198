import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useGet } from 'hooks';
import { Token } from 'components/common/TokenInput';
import styled from 'styled-components';
import Filter from '../Purchasing/helpers/FilterField';
import {
  performPurchasingSearch,
  clearPurchasingFilters,
} from 'actions/organisation';
import { Button } from 'components/common';
import {
  purchasers,
  purchaserStates,
  purchaserCountries,
} from 'services/inputTokens';
import { OrgPurchasingSearchContext } from '..';
import { purchasingCategoriesRemote } from 'services/inputTokens';

const Content = styled.div`
  padding: 0px 0px 10px;
  > * {
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 5px;
    }
  }
`;

export const Actions = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

const OrganisationPurchasingFilters = ({ organisation }) => {
  const postSearch = useContext(OrgPurchasingSearchContext);
  const dispatch = useDispatch();
  const [{ res: countriesResponse, loading: countriesLoading }] = useGet({
    url: purchaserCountries,
    onMount: true,
  });
  const [
    { res: purchasingTypesResponse, loading: purchasingTypesLoading },
  ] = useGet({
    url: purchasingCategoriesRemote,
    onMount: true,
  });

  const handleSearch = () => {
    dispatch(
      performPurchasingSearch(postSearch, {
        organisationId: organisation.id,
        newSearch: true,
      })
    );
  };

  const handleClear = () => {
    dispatch(clearPurchasingFilters);
  };
  return (
    <>
      <Content>
        <Filter
          filterType='select'
          filterId='purchaserType'
          placeholder='Purchaser Type'
          options={
            purchasingTypesLoading
              ? []
              : purchasingTypesResponse.map(o => ({
                  label: o.categoryName,
                  value: o.categoryName,
                }))
          }
          mode={'multiple'}
          style={{ width: '100%' }}
          tagRender={tagRender}
        />
        <Filter
          filterType='token'
          filterId='purchaserName'
          placeholder='Purchaser Name'
          options={purchasers}
        />
        <Filter
          allowClear={false}
          filterType='select'
          filterId='purchaserCountry'
          placeholder='Purchaser Country'
          options={
            countriesLoading
              ? []
              : countriesResponse.map(o => ({
                  label: o.countryName,
                  value: o.countryName,
                }))
          }
          displayKey='displayName'
          onTheFly={false}
          mode={'multiple'}
          style={{ width: '100%' }}
          tagRender={tagRender}
        />
        <Filter
          filterType='token'
          placeholder='Purchaser State'
          filterId='purchaserState'
          options={purchaserStates}
          displayKey='displayName'
          onTheFly={false}
        />
        <Filter
          filterType='token'
          placeholder='Purchaser Zip'
          filterId='purchaserZip'
        />
        <Filter filterType='token' filterId='item' placeholder='Item' />
        <Filter
          filterType='datePicker'
          filterId='date'
          type='range'
          placeholder={['Purchase Date From', 'Purchase Date To']}
        />
        <Filter
          filterType='number'
          filterId='minPrice'
          min={0}
          placeholder={'Min. Price (USD)'}
        />
        <Filter
          filterType='number'
          filterId='maxPrice'
          min={0}
          placeholder={'Max. Price (USD)'}
        />
      </Content>
      <Actions>
        <Button data-testid='filter-reset' onClick={handleClear}>
          Reset
        </Button>
        <Button
          onClick={handleSearch}
          type='primary'
          style={{ marginLeft: '10px' }}
          data-testid='filter-update'
        >
          Update
        </Button>
      </Actions>
    </>
  );
};

const tagRender = props => {
  const { label, onClose } = props;
  const onPreventMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Token
      key={label}
      value={label}
      removeToken={onClose}
      style={{ marginTop: 'auto', marginBottom: 'auto' }}
      onMouseDown={onPreventMouseDown}
    />
  );
};

export default OrganisationPurchasingFilters;
