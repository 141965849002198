import styled from 'styled-components';

const Container = styled.div`
  margin-left: auto;
`;

const ProductFruitAnchor = () => {
  return <Container id='productFruit' />;
};

export default ProductFruitAnchor;
