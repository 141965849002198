import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setFilter, performSearch } from 'actions/search';
import { getFilter, getSearchRequest } from 'selectors/search';
import { Select, DatePicker } from 'components/common';
import { SearchContext } from '../../';
import { commonDateRanges } from 'services/inputTokens';

const getDateValue = (date = []) => {
  if (Array.isArray(date)) {
    return 'custom';
  } else {
    return date;
  }
};

const QuickDate = ({ disabled }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(getSearchRequest);
  const { postSearch, postGeoSearch } = useContext(SearchContext);

  const handleDateChange = value => {
    if (value === 'custom' || !value) {
      dispatch(setFilter('date', value));
    } else {
      dispatch(setFilter('date', value));
      dispatch(performSearch(postSearch, postGeoSearch));
    }
  };

  const date = useSelector(getFilter('date'));
  const dateValue = getDateValue(date);

  if (!commonDateRanges.some(item => item.value === dateValue)) {
    handleDateChange('all');
  }

  return (
    <>
      <span>Date:</span>
      <Select
        style={{ width: 135 }}
        size='small'
        options={commonDateRanges}
        onChange={handleDateChange}
        value={dateValue}
        disabled={loading | disabled}
        data-testid='filter-date'
      />
      {dateValue === 'custom' && (
        <div style={{ display: 'inline-block' }}>
          <DatePicker
            disabled={loading}
            placeholder={['Activity From', 'Activity To']}
            type='range'
            onChange={handleDateChange}
            size='small'
            value={date}
          />
        </div>
      )}
    </>
  );
};

export default QuickDate;
