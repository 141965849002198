import Date from './Date';
import SortBy from './SortBy';
import { FiltersContainer } from '../styled';
import { view as viewConstants } from '../constants';
import RegionSelector from '../Map/RegionSelector';
import OrgCategory from './OrgCategory';
import { IsMap } from '../helpers';

const QuickFilters = ({ view, loading, disableSorting, hasMapError }) => {
  return (
    <FiltersContainer>
      {(view === viewConstants.researcher ||
        view === viewConstants.organisation) && (
        <SortBy disabled={disableSorting} />
      )}
      {!IsMap(view) && <Date />}
      {(view === viewConstants.researcher ||
        view === viewConstants.organisation) && (
        <OrgCategory filterId='organisationCategory' />
      )}
      {IsMap(view) && (
        <RegionSelector loading={loading} hasMapError={hasMapError} />
      )}
    </FiltersContainer>
  );
};

export default QuickFilters;
