import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faBell,
  faList,
  faCalendarAlt,
  faCog,
  faDollarSign,
  faSearchDollar,
  faSignOutAlt,
  faUser,
  faCode,
  faUsers,
  faAward,
  faImage,
  faBookOpen,
  faStethoscope,
  faAt,
  faBuilding,
  faMapMarkerAlt,
  faGlobe,
  faTimes,
  faFileAlt,
  faEdit,
  faTimesCircle,
  faCheckCircle,
  faComments,
  faCloud,
  faExternalLinkAlt,
  faProjectDiagram,
  faPenNib,
  faQuoteRight,
  faAsterisk,
  faSearch,
  faLeaf,
  faFingerprint,
  faCalculator,
  faFileUpload,
  faQuestion,
  faPhone,
  faCaretUp,
  faCaretDown,
  faPlus,
  faMinus,
  faBriefcase,
  faUserSecret,
  faQuestionCircle,
  faGlobeAmericas,
  faLayerGroup,
  faClock,
  faClockRotateLeft,
  faMapSigns,
  faPauseCircle,
  faTachometerAlt,
  faHistory,
  faExclamationCircle,
  faRocket,
  faCity,
  faFolderOpen,
  faPlay,
  faFlagUsa,
  faCheck,
  faExclamationTriangle,
  faEllipsisV,
  faSackDollar,
  faHandHoldingDollar,
  faCircleInfo,
  faChevronDown,
  faCopy,
  faMinusCircle,
  faMoneyCheckDollar,
  faHandshake,
  faRobot,
  faFlag,
  faTrash,
  faArrowRightArrowLeft,
  faMagnifyingGlassChart,
  faMagnifyingGlassPlus,
  faCertificate,
  faRotate,
  faLocationDot,
  faMessage,
  faStore,
  faXmark,
  faArrowsRotate,
} from '@fortawesome/free-solid-svg-icons';

import {
  faLinkedin,
  faFacebookSquare,
  faGoogle,
  faTwitterSquare,
  faInstagramSquare,
  faResearchgate,
  faSalesforce,
  faHubspot,
} from '@fortawesome/free-brands-svg-icons';

import AcademiaIcon from './AcademiaIcon';
import SparklesIcon from './SparklesIcon';

const icons = {
  accept: faCheckCircle,
  author: faPenNib,
  award: faAward,
  bell: faBell,
  book: faBookOpen,
  calculator: faCalculator,
  calendar: faCalendarAlt,
  cancel: faTimesCircle,
  caret: faAngleRight,
  chat: faComments,
  circleInfo: faCircleInfo,
  citation: faQuoteRight,
  code: faCode,
  company: faBuilding,
  crm: faCloud,
  delete: faTimes,
  dollar: faDollarSign,
  edit: faEdit,
  email: faAt,
  facebook: faFacebookSquare,
  fingerprint: faFingerprint,
  globe: faGlobe,
  google: faGoogle,
  handHoldingDollar: faHandHoldingDollar,
  help: faQuestion,
  image: faImage,
  instagram: faInstagramSquare,
  keywords: faSearch,
  link: faExternalLinkAlt,
  linkedin: faLinkedin,
  list: faList,
  map: faMapMarkerAlt,
  mesh: faLeaf,
  network: faProjectDiagram,
  pause: faPauseCircle,
  phone: faPhone,
  reference: faAsterisk,
  report: faFileAlt,
  researchgate: faResearchgate,
  sackDollar: faSackDollar,
  searchDollar: faSearchDollar,
  settings: faCog,
  signOut: faSignOutAlt,
  sortAsc: faCaretUp,
  sortDesc: faCaretDown,
  trial: faStethoscope,
  twitter: faTwitterSquare,
  upload: faFileUpload,
  user: faUser,
  users: faUsers,
  plus: faPlus,
  minus: faMinus,
  briefcase: faBriefcase,
  private: faUserSecret,
  public: faGlobeAmericas,
  verified: faCheckCircle,
  unverified: faQuestionCircle,
  shared: faUsers,
  category: faLayerGroup,
  clock: faClock,
  clockRotateLeft: faClockRotateLeft,
  mapSigns: faMapSigns,
  impactScore: faTachometerAlt,
  history: faHistory,
  error: faExclamationCircle,
  rocket: faRocket,
  city: faCity,
  folderOpen: faFolderOpen,
  play: faPlay,
  flagUsa: faFlagUsa,
  check: faCheck,
  warning: faExclamationTriangle,
  options: faEllipsisV,
  chevronDown: faChevronDown,
  copy: faCopy,
  salesforce: faSalesforce,
  hubspot: faHubspot,
  handShake: faHandshake,
  valid: faCheckCircle,
  invalid: faTimesCircle,
  toBeValidated: faMinusCircle,
  dollarNote: faMoneyCheckDollar,
  robot: faRobot,
  flag: faFlag,
  trash: faTrash,
  magnifyingGlass: faMagnifyingGlassChart,
  magnifyingGlassPlus: faMagnifyingGlassPlus,
  rightArrowLeft: faArrowRightArrowLeft,
  certificate: faCertificate,
  rotate: faRotate,
  locationDot: faLocationDot,
  message: faMessage,
  store: faStore,
  close: faXmark,
  refresh: faArrowsRotate,
};

const Icon = ({
  color = 'white',
  icon,
  size = '2x',
  style,
  className,
  onClick,
  disabled,
  title,
  'data-testid': dataTestId = 'icon',
}) => {
  const customIcons = {
    academia: AcademiaIcon,
    sparkles: SparklesIcon,
  };

  if (customIcons[icon]) {
    const CustomIcon = customIcons[icon];
    return <CustomIcon onClick={onClick} color={color} style={style} />;
  } else {
    if (icon && icons[icon]) {
      return (
        <FontAwesomeIcon
          data-testid={dataTestId}
          icon={icons[icon]}
          size={size}
          color={disabled ? 'rgba(0,0,0,0.25)' : color}
          style={style}
          className={className}
          onClick={disabled ? null : onClick}
          title={title}
        />
      );
    } else {
      return <div></div>;
    }
  }
};

export default Icon;
