import storage from 'services/storage';

export const types = {
    SET_USER_SETTING: 'SET_USER_SETTING',
    RESTORE_STATE: 'RESTORE_STATE'
};

const persistState = async (dispatch, getState) => {
const state = getState();
await storage.setItem('userSettings', state.userSettings);
};

export const restoreState = state => ({
    type: types.RESTORE_STATE,
    payload: state,
});

export const setUserSetting = (filter, value)   => async dispatch => {
await dispatch({
    type: types.SET_USER_SETTING,
    payload: { filter, value },
});
await dispatch(persistState);
};

