import { Select as AntSelect } from 'antd';
import { useSelector } from 'react-redux';
import { getActiveList } from 'selectors/lists';
import { Select, OptionContainer, ListOption } from './styled';
import { Tooltip } from 'antd';

const { Option: AntOption } = AntSelect;

const renderOption = ({ id, name, description, dataTestId }, i) => {
  return (
    <AntOption
      key={`${id}-${i}`}
      value={id}
      name={name}
      data-testid={`${dataTestId}-option-${i}`}
    >
      <OptionContainer>
        {description ? (
          <Tooltip
            title={description}
            placement='right'
            overlayStyle={{ maxWidth: '400px', maxHeight: '200px' }}
          >
            <ListOption>{name}</ListOption>
          </Tooltip>
        ) : (
          <ListOption>{name}</ListOption>
        )}
      </OptionContainer>
    </AntOption>
  );
};

const Selector = ({ disabled, lists, onSelectorChange }) => {
  const activeList = useSelector(getActiveList);
  const dataTestId = 'select-list';

  return (
    <Select
      options={lists}
      disabled={disabled}
      placeholder={disabled ? 'Loading...' : 'Select a list'}
      value={disabled ? 'Loading...' : activeList.id}
      onChange={onSelectorChange}
      renderOption={(o, i) => renderOption({ ...o, dataTestId }, i)}
      optionFilterProp='name'
      showSearch
      style={{ maxWidth: '50vw' }}
    />
  );
};

export default Selector;
