import styled from 'styled-components';
import Button from '../Button';

export const Container = styled.div`
  grid-template-columns: repeat(${p => p.columns}, auto);
  display: grid;
  width: 100%;
  font-size: 13px;

  .header {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 11px;
    background: rgba(242, 242, 242, 1);
    display: flex;
    align-items: center;
    user-select: none;
    padding: ${p => p.cellPadding};
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    > svg {
      font-size: 12px;
      margin-left: 4px;
    }
    position: sticky;
    top: 0;
  }

  .cell {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: ${p => p.cellPadding};
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
  }

  .cell .rightAlign {
    margin-left: auto;
    margin-right: 5px;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${p => p.theme.borderGrey};
  padding-top: ${p => p.theme.gutter};
  padding-bottom: ${p => p.theme.gutter};
  font-weight: 500;
  font-size: 13px;
  flex-wrap: wrap;
`;

export const FooterInfo = styled.div`
  border-left: 2px solid ${p => p.theme.borderGrey};
  margin-left: 8px;
  padding-left: 8px;
`;

export const ExpandCell = styled.div.attrs(p => ({
  style: {
    gridArea: `${p.index * 2 + 2} / 1`,
  },
}))`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HiddenCellRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid rgb(242, 242, 242);
  padding-top: 5px;
  padding-bottom: 5px;

  .cellTitle {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 11px;
    margin-right: 5px;
    margin-left: 15px;
  }
`;

export const MaxContentCell = styled.div.attrs(p => ({
  style: {
    maxWidth: `${p.maxContentWidth}px`,
  },
}))`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HiddenArea = styled.div`
  padding-top: 5px;
  > :nth-child(odd) {
    background-color: rgb(250, 250, 250);
  }
`;

export const SortDropDown = styled(Button).attrs(p => ({
  size: 'small',
}))`
  position: relative;
  margin-left: -4px;
  cursor: pointer;
  border-color: transparent;
  background-color: transparent !important;
  box-shadow: none;
  color: inherit !important;
`;

export const SortOptionContainer = styled.div`
  position: absolute;
  top: calc(100% + 12px);
  left: 0;
  z-index: 5;
`;

export const SortOption = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
  display: flex;
  align-items: center;
  > svg {
    font-size: 12px;
    margin-left: 4px;
  }
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;
