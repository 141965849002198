import styled from 'styled-components';
import { Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';
import { dismissClinicalTrialNotice } from 'actions/organisation';
import { getShowClinicalTrialNotice } from 'selectors/organisation';

const StyledAlert = styled(Alert)`
  margin-bottom: ${p => p.theme.gutter};
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 10px;
  }
`;

const Message = ({ onDismiss }) => {
  return (
    <MessageContainer>
      <span>
        The data in this section presents information about clinical trials in
        which this organisation is involved as a lead sponsor or a co-sponsor
      </span>
      <Button onClick={onDismiss}>Got it</Button>
    </MessageContainer>
  );
};

const ClinicalTrialNotice = () => {
  const showNotice = useSelector(getShowClinicalTrialNotice);
  const dispatch = useDispatch();

  if (!showNotice) return null;

  const handleDismiss = () => {
    dispatch(dismissClinicalTrialNotice);
  };

  return (
    <StyledAlert type='info' message={<Message onDismiss={handleDismiss} />} />
  );
};

export default ClinicalTrialNotice;
