import { Fragment, useEffect, useState } from 'react';
import { Pane, Loader, Table } from 'components/common';
import styled from 'styled-components';
import { userSettings } from 'services/api';
import { useAuth, useGet } from 'hooks';
import LicenseCheckBox from './LicenseCheckbox';
import { GetValidatedUserSettings, userSettingToStringMap, getUserSettingsFromUrl } from './userSettingsType';
import { useSelector } from 'react-redux';
import { getUserSettings } from 'selectors/users';

const UserSearchHistoryTable = styled(Table)`
  margin-top: 10px;
  max-height: 72vh;
  overflow-y: auto;
`;

const LoadingReportComponent = () => {
  return (
    <Pane title='User Report'>
      <Loader />
    </Pane>
  );
};

export default function UserSettings(){

    const { user } = useAuth();

    const [{ res: fetchedUserSettings, loading: getUserSettingsLoading,   error: hasError  }, fetchUserSettings] = useGet({
      url: userSettings
    });

    const [data, setData] = useState([]);
    const getValidatedUserSettingsFromState = useSelector(getUserSettings);

    useEffect(() => {
      if(user)
      {
        const validatedUserSettingsFromUrl = GetValidatedUserSettings(getUserSettingsFromUrl, user);
        if(validatedUserSettingsFromUrl && validatedUserSettingsFromUrl.length > 0)
        {
          fetchUserSettings({query:{userSettings:validatedUserSettingsFromUrl}});
        }
      }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
      if(fetchedUserSettings)
      {
        setData(fetchedUserSettings.concat(getValidatedUserSettingsFromState));
      }
      else
      {
        setData(getValidatedUserSettingsFromState);
      }
    }, [fetchedUserSettings, getValidatedUserSettingsFromState, hasError])

    const config = 
    {
        headers: [
          { label: 'Setting Type', key: '' },
          { label: 'Value', key: ''},
        ],
        createRow: (r, i) => (
          <Fragment key={`$${r.userSettingType}`}>
            <div style={{}}>{userSettingToStringMap[r.userSettingType]}</div>
            <div><LicenseCheckBox licenseName={r.userSettingType} value={r.value} /> </div>
          </Fragment>
        ),
      };

  if (getUserSettingsLoading && !hasError) return <LoadingReportComponent />;

    return(
      <Pane title='User Settings'>
        <UserSearchHistoryTable
            data={data}
            config={config}
        />
      </Pane>
    );
}