import { types } from 'actions/alerts';

const defaultState = {
  view: 'savedSearches',
  researcherFilter: '',
  researcherToUnfollow: null,
  organisationFilter: '',
  organisationToUnfollow: null,
  alertToDelete: null,
  alertToEdit: null,
  alertToCreate: null,
  alertsFilter: ''
};

const REDUCER = {
  [types.SET_VIEW]: (state, action) => ({
    ...state,
    view: action.payload.view,
  }),
  [types.SET_RESEARCHER_FILTER]: (state, action) => ({
    ...state,
    researcherFilter: action.payload.researcherFilter,
  }),
  [types.SET_RESEARCHER_TO_UNFOLLOW]: (state, action) => ({
    ...state,
    researcherToUnfollow: action.payload.researcherToUnfollow,
  }),
  [types.SET_ALERT_TO_DELETE]: (state, action) => ({
    ...state,
    alertToDelete: action.payload.alertToDelete,
  }),
  [types.SET_ALERT_TO_EDIT]: (state, action) => ({
    ...state,
    alertToEdit: action.payload.alertToEdit,
  }),
  [types.SET_ALERT_TO_CREATE]: (state, action) => ({
    ...state,
    alertToCreate: action.payload.alertToCreate,
  }),
  [types.SET_ALERT_FILTER]: (state, action) => ({
    ...state,
    alertsFilter: action.payload.alertsFilter,
  }),
  [types.SET_ORGANISATION_FILTER]: (state, action) => ({
    ...state,
    organisationFilter: action.payload.organisationFilter,
  }),
  [types.SET_ORGANISATION_TO_UNFOLLOW]: (state, action) => ({
    ...state,
    organisationToUnfollow: action.payload.organisationToUnfollow,
  }),
};

const reducer = (state = defaultState, action) => {
  const handler = REDUCER[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
