import { Token } from "./TokenInput";

export const tagRender = props => {
    const { label, onClose } = props;
    const onPreventMouseDown = event => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Token
            key={label}
            value={label}
            removeToken={onClose}
            style={{ marginTop: '2px', marginBottom: '2px' }}
            onMouseDown={onPreventMouseDown}
        />
    );
};
