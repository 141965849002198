import { useCallback, useEffect } from 'react';
import qs from 'query-string';

import useXHR from './useXHR';

const useGet = ({ url, query, onMount, responseType }) => {
  const [state, req, setRes] = useXHR(onMount);
  const queryString = query ? qs.stringify(query) : null;

  const send = useCallback(
    ({ url: newUrl, query: newQuery } = {}) => {
      const latestUrl = newUrl || url;
      const latestQuery = newQuery ? qs.stringify(newQuery) : queryString;

      const urlToOpen = latestQuery ? `${latestUrl}?${latestQuery}` : latestUrl;

      req.open('GET', urlToOpen);
      if (responseType) {
        req.responseType = responseType;
      }
      req.send();
    },
    [url, queryString, req, responseType]
  );

  const cancel = useCallback(() => {
    req?.abort();
  }, [req]);

  useEffect(() => {
    if (onMount) {
      send();
    }
    return () => req.abort();
  }, [onMount, send, req]);

  return [state, send, setRes, cancel];
};

export default useGet;
