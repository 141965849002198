import { Fragment, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Modal as AntModal } from 'antd';
import { Tooltip } from 'antd';

import { Select, Table } from 'components/common';
import { Link } from 'react-router-dom';
import { usePost, usePatch } from 'hooks';
import { format } from 'utils/date';
import {
  researchersLists,
  manageMembers,
  organisationsLists,
} from 'services/api';
import {
  NotificationFailure,
  NotificationSuccessAssignOneResearcherToList,
} from 'components/Lists/Notifications';
import { LABEL_BUTTON_ADD_NEW_LIST } from 'components/common/labelConstants';
import { views } from 'components/Lists/constants';
import listRenderOption from 'components/common/Lists/ListRenderOptions';
import TooltipContent from 'components/common/TooltipContent';

const handleClick = (list, view = views.researchers) => {
  const url = list.enabled
    ? `/lists/${list.listId}?view=${view}`
    : `/lists/${list.listId}?includePausedList=true&view=${view}`;
  window.open(url, '_blank');
};

const getConfig = view => ({
  headers: ['List', 'Added On', 'Added By'],
  createRow: (r, i) => (
    <Fragment key={r.listId}>
      <Tooltip
        title={<TooltipContent label={r.name} description={r.description} />}
        placement='right'
        overlayStyle={{ maxWidth: '650px' }}
      >
        <Link to='#' onClick={() => handleClick(r, view)}>
          <div>{r.name}</div>
        </Link>
      </Tooltip>
      <div>{format(r.dateAddedUtc)}</div>
      <div>{r.addedByUser}</div>
    </Fragment>
  ),
});

const Container = styled.div`
  padding-top: 10px;
`;

const StyledTable = styled(Table)`
  overflow: auto;
  max-height: 225px;
`;

const DescriptionContainer = styled.div`
  font-size: 13px;
  line-height: 1.3;
  padding-top: 10px;
  background-color: #f2f8fb;
  color: #595959;
  padding: 10px;
  margin-top: 10px;
`;

const Heading = styled.div`
  color: #87c6fe;
  margin-bottom: 10px;
`;

const ListDescription = ({ description }) => {
  return (
    <DescriptionContainer>
      <div>
        <Heading>LIST DESCRIPTION</Heading>
        <div>{description}</div>
      </div>
    </DescriptionContainer>
  );
};

const Lists = ({
  editableLists,
  loadingLists,
  onCreateNewItemList,
  newListCreatedId,
  type = views.researchers,
  researcherName,
  organisationName,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [listToAdd, setListToAdd] = useState({});
  const { id } = useParams();
  const [{ res: lists }, fetchLists] = usePost({
    url: type === views.researchers ? researchersLists : organisationsLists,
    body: { researcherIds: [id], memberIds: [+id] },
    onMount: true,
  });
  const [
    {
      res: addRes,
      loading: addinMemberLoading,
      error: addingMembersToListError,
    },
    patchMembers,
  ] = usePatch({
    url: '',
  });
  const config = useMemo(() => getConfig(type), [type]);

  useEffect(() => {
    setShowModal(false);

    if (addRes) {
      fetchLists();
      NotificationSuccessAssignOneResearcherToList(null, type);
    }

    if (addingMembersToListError && addingMembersToListError.status === 400) {
      NotificationFailure(addingMembersToListError.message, false, type);
    }
  }, [addRes, fetchLists, addingMembersToListError, type]);

  useEffect(() => {
    if (newListCreatedId) {
      fetchLists();
    }
  }, [newListCreatedId, fetchLists]);

  const handleChange = id => {
    if (id === 'no_lists') return;
    setShowModal(true);
    setListToAdd(editableLists.find(l => l.value === id));
  };

  const handleOk = () => {
    patchMembers({
      url: manageMembers(listToAdd.value, type),
      body: { researcherIds: [id], memberIds: [+id] },
    });
  };

  let _lists = [];
  if (lists) {
    _lists = Object.values(lists)[0];
  }

  const editableListOptions =
    editableLists && editableLists.length === 0
      ? [{ label: 'No lists available', value: 'no_lists' }]
      : editableLists;

  return (
    <Container>
      <Select
        placeholder='Add to List'
        disabled={!editableLists || loadingLists}
        value={'Add to List'}
        onCreateNewItem={onCreateNewItemList}
        buttonText={LABEL_BUTTON_ADD_NEW_LIST}
        onChange={handleChange}
        options={editableListOptions}
        style={{ width: '100%', marginBottom: '10px' }}
        renderOption={(o, i) => listRenderOption({ ...o }, i)}
        filterOption={(input, option) =>
          option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
      />
      <StyledTable config={config} data={_lists || []} />
      <AntModal
        open={showModal}
        title={`Add ${
          type === views.researchers ? 'Researcher' : 'Organisation'
        } to List`}
        onCancel={() => setShowModal(false)}
        onOk={handleOk}
        okButtonProps={{ disabled: addinMemberLoading }}
        cancelButtonProps={{ disabled: addinMemberLoading }}
        okText={
          type === views.researchers ? 'Add Researcher' : 'Add Organisation'
        }
      >
        Would you like to add{' '}
        <strong>
          {type === views.researchers ? researcherName : organisationName}
        </strong>{' '}
        to <strong>{listToAdd.label}</strong> list?
        {listToAdd.description && (
          <ListDescription description={listToAdd.description} />
        )}
      </AntModal>
    </Container>
  );
};

export default Lists;
