import { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAuth, usePost } from 'hooks';
import { format } from 'utils/date';
import { chatOpen, chatClose } from 'services/api';
import { licenseTypeToStringMap } from 'models/licenseType';
import { getNeedPassCaptcha } from 'selectors/rateLimits';

const useFreshChat = () => {
  const [chatIsLoaded, setChatIsLoaded] = useState(false);
  const { user, isAuthed } = useAuth();
  const needPassCaptcha = useSelector(getNeedPassCaptcha);

  const [, postChatOpen] = usePost({ url: chatOpen });
  const [, postChatClose] = usePost({ url: chatClose });

  const setChatProps = useCallback(() => {
    const { fcWidget } = window;

    if (!fcWidget) return;

    fcWidget.setConfig({ hideFAQ: false });
    fcWidget.setExternalId(user.userId);
    fcWidget.user.setProperties({
      firstName: user.userName
        ? user.userName.split(' ')[0]
        : user.displayName.split(' ')[0],
      email: user.userEmail || user.email,
      organisation: user.userCompanyName || user.companyName,
      lastLoggedOn: format(user.userLastLogin, 'DD/MM/YYYY'),
      userId: user.userId,
      accountId: user.accountId,
      isAccountManager: user.isAccountManager,
      isUserManager: user.isUserManager,
      canPushToSalesforce: user.canPushToSalesforce,
      canPushToHubspot: user.canPushToHubspot,
      licenses: user.licenses
        .map(
          x =>
            `${x.demoMode ? 'Trial' : 'Full'} ${
              licenseTypeToStringMap[x.type]
            } expiry date: ${format(x.expiration, 'DD/MM/YYYY')}`
        )
        .join(', '),
    });

    fcWidget.on('widget:opened', () => postChatOpen());
    fcWidget.on('widget:closed', () => postChatClose());
  }, [user, postChatOpen, postChatClose]);

  useEffect(() => {
    const config = {
      token: 'af8aaf7f-94ab-4a14-8a5d-8130b13c9765',
      host: 'https://wchat.freshchat.com',
    };
    const { fcWidget } = window;

    if (!fcWidget) return;

    fcWidget.init(config);
    fcWidget.setConfig({ hideFAQ: true });
    if (!user) return;
    setChatProps();
  }, [isAuthed, setChatProps, user]);

  useEffect(() => {
    const { fcWidget } = window;

    if (!fcWidget) return;

    fcWidget.on('widget:loaded', () => setChatIsLoaded(true));
  }, []);

  useEffect(() => {
    if (chatIsLoaded) {
      const { fcWidget } = window;
      // enter code form
      if (isAuthed && user.tfaEnabled && user.totpVerified === false) {
        fcWidget.setFaqTags({ tags: ['2fa'], filterType: 'category' });
        setChatProps();
        return;
      }

      if (needPassCaptcha) {
        fcWidget.setFaqTags({ tags: ['limits'], filterType: 'category' });
        return;
      }

      if (user && user.userEmail) {
        fcWidget.setFaqTags({});
      }
    }
  }, [chatIsLoaded, isAuthed, needPassCaptcha, setChatProps, user]);
};

export default useFreshChat;
