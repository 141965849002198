import { Suspense, lazy} from 'react';
import styled from 'styled-components';

import { Pane, Loader } from 'components/common';
import AuthedRoute from './AuthedRoute';
import CRMIntegrations from './CRMIntegrations';
// import { LoadingReportComponent } from './Report';
import Keywords from './Keywords';
import EmailRequests from './EmailRequests';
import Users from './Users';
import UserSettings from './UserSettings';
import { LeftContainer } from './styled';
import Nav from './Nav';

const LoadingReportComponent = () => {
  return (
    <Pane title='User Report'>
      <Loader />
    </Pane>
  );
};

const Report = lazy(() => import('./Report'));

const Container = styled.div`
  margin-top: ${p => p.theme.gutter};
  display: flex;
  align-items: flex-start;
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: column;
  }
`;

export const isEmailRequestsRouteAvailable = user =>
  !user.hideResearcherEmail && user.licenses.some(x => !x.demoMode);

const Settings = () => {
  return (
    <Container>
      <LeftContainer>
        <Nav />
      </LeftContainer>
      <AuthedRoute
        path='/settings/crm'
        redirect='/settings'
        validate={u => u.canPushToSalesforce || u.canPushToHubspot}
      >
        <CRMIntegrations />
      </AuthedRoute>
      <AuthedRoute
        path='/settings/userSettings'
        redirect='/settings'
        validate={u => true}
      >
        <UserSettings />
      </AuthedRoute>
      <AuthedRoute
        path='/settings/users'
        redirect='/settings'
        validate={u => u.isUserManager}
      >
        <Users />
      </AuthedRoute>
      <AuthedRoute
        path='/settings/report'
        redirect='/settings'
        validate={u => u.isUserManager}
      >
        <Suspense fallback={<LoadingReportComponent />}>
          <Report />
        </Suspense>
      </AuthedRoute>
      <AuthedRoute
        path='/settings/keywords'
        redirect='/settings'
        validate={u => u.isAccountManager}
      >
        <Keywords />
      </AuthedRoute>
      <AuthedRoute
        needs={isEmailRequestsRouteAvailable}
        path='/settings/email-requests'
        redirect='/settings/crm'
      >
        <EmailRequests />
      </AuthedRoute>
    </Container>
  );
};

export default Settings;
