import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setShowBuilder } from 'actions/search';
import { Form, Button, Icon } from 'components/common';
import { DisabledButtonWrapper } from 'components/common/styled.js';
import { Tooltip } from 'antd';
import { ActionButtons, SearchAreaContainer } from './styled';
import { getShowBuilder } from 'selectors/search';
import SearchBuilder from '../../Search/SearchArea/SearchBuilder';
import OrgFilterField from '../Filters/OrgFilterField';
import { setOrgClinicalTrialFilterGroups } from 'actions/organisation';
import { SearchBar } from '.';
import {
  getHasValidClinicalTrialSearchQuery,
  getOrganisationClinicalTrialFilter,
  SLICE,
} from 'selectors/organisation';
import { performBoolSearch, performPhraseSearch } from 'selectors/users';
import BoolErrorMessage from 'components/Search/BoolErrorMessage';
import { SearchDiv } from 'components/Item/styled';

export default function OrganisationClinicalTrialSearchArea({
  organisation,
  handleSearch,
}) {
  const dispatch = useDispatch();
  const showBuilder = useSelector(getShowBuilder);

  const handleClear = () => {
    dispatch(setOrgClinicalTrialFilterGroups(organisation.id, 'term', ''));
    handleSearch();
  };

  const searchTermRef = useRef();

  const [showBoolError, setShowBoolError] = useState(false);
  const getHasBoolError = useSelector(
    performBoolSearch(SLICE, organisation.id, 'clinicalTrialFilters')
  );
  const getHasPhraseError = useSelector(
    performPhraseSearch(SLICE, organisation.id, 'clinicalTrialFilters')
  );

  const handleSearchBuilderClear = () => {
    dispatch(setOrgClinicalTrialFilterGroups(organisation.id, 'term', ''));
  };

  const handleSearchBuilderSearch = value => {
    dispatch(setOrgClinicalTrialFilterGroups(organisation.id, 'term', value));
    handleSearch();
  };

  const handleShowBuilder = () => {
    dispatch(setShowBuilder(true));
  };

  const hasValidQuery = useSelector(
    getHasValidClinicalTrialSearchQuery(organisation.id)
  );

  const handleLocalSearch = () => {
    if (showBoolError !== null && (getHasBoolError || getHasPhraseError))
      setShowBoolError(true);
    else handleSearch();
  };

  const searchButton = (
    <Button
      type='primary'
      onClick={handleLocalSearch}
      data-testid='search-button'
      disabled={!hasValidQuery}
    >
      Search
    </Button>
  );

  return (
    organisation && (
      <SearchAreaContainer style={{ marginBottom: 10 }}>
        <SearchBar>
          <Button
            style={{ marginRight: 10 }}
            type='secondary'
            onClick={handleShowBuilder}
          >
            <Icon icon='magnifyingGlassPlus' color='#8D8D8D' size='sm' />
            <span style={{ marginLeft: '5px' }}>Open Search Builder</span>
          </Button>
          <SearchDiv>
            <Form width='100%' onSubmit={handleLocalSearch} style={{ flex: 1 }}>
              <OrgFilterField
                placeholder='(NGS OR "Next generation sequencing") AND CRISPR'
                filterId='term'
                organisationId={organisation.id}
                filterType='input'
                data-testid='org-filter-term'
                size='default'
                setFilterGroup={setOrgClinicalTrialFilterGroups}
                getFilter={getOrganisationClinicalTrialFilter}
              />
            </Form>
            {showBoolError ? (
              <BoolErrorMessage
                performSearch={handleSearch}
                hasBoolFailed={getHasBoolError}
                hasParseFailed={getHasPhraseError}
                updateSearchQuery={value => handleSearchBuilderSearch(value)}
                term={getOrganisationClinicalTrialFilter(
                  organisation.id,
                  'term'
                )}
                searchTermRef={searchTermRef}
                setShowBoolError={setShowBoolError}
              />
            ) : (
              <></>
            )}
          </SearchDiv>
          <ActionButtons>
            <Button
              type='secondary'
              onClick={handleClear}
              data-testid='clear-search-button'
            >
              Clear
            </Button>
            {hasValidQuery ? (
              searchButton
            ) : (
              <Tooltip title='Enter search criteria'>
                <DisabledButtonWrapper>{searchButton}</DisabledButtonWrapper>
              </Tooltip>
            )}
          </ActionButtons>
        </SearchBar>
        {!!showBuilder && (
          <SearchBuilder
            onSearch={handleSearchBuilderSearch}
            showAdvancedFields={false}
            id={organisation.id}
            saveInStore={false}
            onClear={handleSearchBuilderClear}
            filterId='term'
            filterGroup='OrganisationClinicalTrials'
          />
        )}
      </SearchAreaContainer>
    )
  );
}
