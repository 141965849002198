import { types } from 'actions/calendar';
import dayjs from 'dayjs';

const defaultState = {
  pageSize: 50,
  pageNumber: 0,
  filters: {
    year: +dayjs().format('YYYY'),
    location: '',
    name: '',
  },
  tableSort: {
    dir: '',
    by: '',
  },
  restoringState: true,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_PAGE_SIZE:
      return {
        ...state,
        pageNumber: action.payload.number,
      };
    case types.SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload.number,
      };
    case types.SET_SORT: {
      const tableSort =
        action.payload.sortKey === state.tableSort.by
          ? state.tableSort.dir === 'asc'
            ? { dir: 'desc', by: state.tableSort.by }
            : defaultState.tableSort
          : { dir: 'asc', by: action.payload.sortKey };
      return {
        ...state,
        tableSort,
      };
    }
    case types.SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.id]: action.payload.value,
        },
      };
    case types.RESTORE_STATE: {
      if (!action.payload) {
        return {
          ...state,
          restoringState: false,
        };
      }

      const restoredState = JSON.parse(JSON.stringify(action.payload));
      restoredState.restoringState = false;
      return restoredState;
    }
    case types.CLEAR_FILTERS:
      return { ...defaultState, restoringState: false };
    default:
      return state;
  }
};

export default reducer;
