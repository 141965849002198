import React from 'react';
import { Radio } from 'components/common';
import { useDispatch } from 'react-redux';
import { setView } from 'actions/search';
import { view as viewConstants } from './constants';
import { FiltersContainer } from './styled';
import { ResearcherSearch } from './helpers';

const ResultsView = ({ hasVisualisations, loading, view }) => {
  const dispatch = useDispatch();

  const handleChangeView = view => {
    dispatch(setView(view));
  };

  let viewOptions = [
    { label: 'Researchers', value: viewConstants.researcher },
    {
      label: 'Insights',
      value: viewConstants.visualisation,
      disabled: !hasVisualisations,
    },
    { label: 'Map', value: viewConstants.map },
  ];

  if (!ResearcherSearch(view)) {
    viewOptions = [
      { label: 'Organisations', value: viewConstants.organisation },
      {
        label: 'Insights',
        value: viewConstants.organisationInsights,
        disabled: !hasVisualisations,
      },
      { label: 'Map', value: viewConstants.organisationMap },
    ];
  }

  return (
    <FiltersContainer>
      <Radio
        disabled={loading}
        value={view}
        options={viewOptions}
        optionType='button'
        onChange={handleChangeView}
        style={{ fontWeight: 'normal' }}
        size='small'
      />
    </FiltersContainer>
  );
};

export default ResultsView;
