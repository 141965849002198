import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';

import { Button, Input, Form, Checkbox } from 'components/common';

const CredentialsForm = ({
  form,
  isLoading,
  handleLogin,
  handleChange,
  handleReset,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const noDetails = !form.username || !form.password;
  return (
    <>
      <Form onSubmit={handleLogin}>
        <Input
          disabled={isLoading}
          ref={ref}
          onChange={handleChange('username')}
          value={form.username}
          placeholder='Email'
        />
        <Input
          disabled={isLoading}
          onChange={handleChange('password')}
          value={form.password}
          placeholder='Password'
          password
        />
        <Checkbox
          disabled={isLoading}
          checked={form.rememberMe}
          onChange={handleChange('rememberMe')}
        >
          Remember me
        </Checkbox>
      </Form>
      <Button
        disabled={isLoading || noDetails}
        onClick={handleLogin}
        type='primary'
      >
        Log In
      </Button>
      <Button disabled={isLoading} onClick={handleReset}>
        Reset Password
      </Button>
    </>
  );
};

CredentialsForm.propTypes = {
  form: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool,
  handleLogin: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
};

CredentialsForm.defaultProps = {
  isLoading: false,
};

export default CredentialsForm;
