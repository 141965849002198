import storage from 'services/storage';

export const types = {
  PERFORM_GET_LISTS: 'SEARCH_PERFORM_GET_LISTS',
  PERFORM_SEARCH: 'SEARCH_PERFORM_SEARCH',
  RESET_FILTERS: 'SEARCH_RESET_FILTERS',
  SET_FILTER_GROUP: 'SEARCH_SET_FILTER_GROUP',
  SET_FILTER: 'SEARCH_SET_FILTER',
  SET_FILTERS_FROM_ALERT: 'SET_FILTERS_FROM_ALERT',
  SET_LISTS_REQUEST: 'SEARCH_SET_LISTS_REQUEST',
  SET_SEARCH_REQUEST: 'SET_SEARCH_REQUEST',
  SET_GEO_SEARCH_DATA: 'SET_GEO_SEARCH_DATA',
  SET_SORT_BY: 'SEARCH_SET_SORT_BY',
  SET_ORG_SORT_BY: 'SEARCH_SET_ORG_SORT_BY',
  SET_VIEW: 'SEARCH_SET_VIEW',
  SET_PAGE: 'SEARCH_SET_PAGE',
  INCREMENT_PAGE: 'SEARCH_INCREMENT_PAGE',
  LOAD_MORE: 'SEARCH_LOAD_MORE',
  CLEAR_FILTERS: 'SEARCH_CLEAR_FILTERS',
  SET_SHOW_REFRESH_DIALOG: 'SEARCH_SET_SHOW_REFRESH_DIALOG',
  CLEAR_NEXT_RESULTS: 'SEARCH_CLEAR_NEXT_RESULTS',
  SET_EXTERNAL_SEARCH: 'SEARCH_SET_EXTERNAL_SEARCH',
  SET_EXTERNAL_SEARCH_FROM_SAVED_SEARCH:
    'SEARCH_SET_EXTERNAL_SEARCH_FROM_SAVED_SEARCH',
  PERFORM_EXTERNAL_SEARCH: 'SEARCH_PERFORM_EXTERNAL_SEARCH',
  SET_FILTERS: 'SEARCH_SET_FILTERS',
  RESTORE_STATE: 'SEARCH_RESTORE_STATE',
  APPEND_FILTER: 'SEARCH_APPEND_FILTER',
  SET_SHOW_BUILDER: 'SEARCH_SET_SHOW_BUILDER',
  SET_QUERY_BUILDER_FIELDS: 'SEARCH_SET_QUERY_BUILDER_FIELDS',
  CLEAR_SEARCH_BUILDER_FIELDS: 'SEARCH_CLEAR_SEARCH_BUILDER_FIELDS',
  SET_LISTS_ASSIGNED_TO_RESEARCHERS: 'SEARCH_SET_LISTS_ASSIGNED_TO_RESEARCHERS',
  PERFORM_SEARCH_LISTS_ASSIGNED_TO_RESEARCHERS:
    'SEARCH_PERFORM_SEARCH_LISTS_ASSIGNED_TO_RESEARCHERS',
  SET_LISTS_ASSIGNED_TO_ORGANISATIONS:
    'SEARCH_SET_LISTS_ASSIGNED_TO_ORGANISATIONS',
  PERFORM_SEARCH_LISTS_ASSIGNED_TO_ORGANISATIONS:
    'SEARCH_PERFORM_SEARCH_LISTS_ASSIGNED_TO_ORGANISATIONS',
  SET_PDF_EXPORT_STATUS: 'SET_PDF_EXPORT_STATUS',
  SET_MAP_REGION: 'SEARCH_SET_MAP_REGION',
  SET_FILTER_SECTION_COLLAPSED: 'SET_FILTER_SECTION_COLLAPSED',
  SET_HAS_DISMISSED_VC_FUNDING_SORT_MODAL:
    'SET_HAS_DISMISSED_VC_FUNDING_SORT_MODAL',
  SHOW_VC_FUNDING_SORT_MODAL: 'SHOW_VC_FUNDING_SORT_MODAL',
  ADD_MEMBERS_TO_LIST_FROM_SEARCH_ERROR:
    'ADD_MEMBERS_TO_LIST_FROM_SEARCH_ERROR',
  RESET_ADD_MEMBERS_TO_LIST_FROM_SEARCH_ERROR:
    'RESET_ADD_MEMBERS_TO_LIST_FROM_SEARCH_ERROR',
};

export const setFilter = (id, value) => ({
  type: types.SET_FILTER,
  payload: { id, value },
});

export const setFilters = filters => ({
  type: types.SET_FILTERS,
  payload: { filters },
});

export const setFiltersFromAlert = (view, filters, date) => ({
  type: types.SET_FILTERS_FROM_ALERT,
  payload: { view, filters, date },
});

export const setExternalSearchFromSavedSearch = (view, filters, date) => ({
  type: types.SET_EXTERNAL_SEARCH_FROM_SAVED_SEARCH,
  payload: { view, filters, date },
});
export const appendFilter = (id, value) => ({
  type: types.APPEND_FILTER,
  payload: { id, value },
});

export const setFilterGroup = (group, id, value) => ({
  type: types.SET_FILTER_GROUP,
  payload: { group, id, value },
});

export const performSearch = (request, geoRequest) => ({
  type: types.PERFORM_SEARCH,
  payload: { request, geoRequest },
});

export const loadMore = request => ({
  type: types.LOAD_MORE,
  payload: { request },
});

const setSearchRequestState = searchState => ({
  type: types.SET_SEARCH_REQUEST,
  payload: { ...searchState },
});

const setGeoSearchRequestState = searchState => ({
  type: types.SET_GEO_SEARCH_DATA,
  payload: { ...searchState },
});

const persistState = async (dispatch, getState) => {
  const state = getState();
  await storage.setItem('search', state.search);
};

export const performSearchListAssignedToResearchers = request => ({
  type: types.PERFORM_SEARCH_LISTS_ASSIGNED_TO_RESEARCHERS,
  payload: { request },
});

export const setListAssignedToResearchers = lstResearchers => ({
  type: types.SET_LISTS_ASSIGNED_TO_RESEARCHERS,
  payload: { ...lstResearchers },
});

export const performSearchListAssignedToOrganisations = request => ({
  type: types.PERFORM_SEARCH_LISTS_ASSIGNED_TO_ORGANISATIONS,
  payload: { request },
});

export const setListAssignedToOrganisatinos = lisOrganisations => ({
  type: types.SET_LISTS_ASSIGNED_TO_ORGANISATIONS,
  payload: { ...lisOrganisations },
});

export const setSearchRequest = searchState => async dispatch => {
  await dispatch(setSearchRequestState(searchState));
  await dispatch(persistState);
};

export const setGeoSearchData = searchState => async dispatch => {
  await dispatch(setGeoSearchRequestState(searchState));
  await dispatch(persistState);
};

export const resetFilters = {
  type: types.RESET_FILTERS,
};

export const clearFilters = {
  type: types.CLEAR_FILTERS,
};

export const setListsRequest = listsState => ({
  type: types.SET_LISTS_REQUEST,
  payload: { ...listsState },
});

export const performGetLists = getLists => ({
  type: types.PERFORM_GET_LISTS,
  payload: { getLists },
});

export const setSortBy = sortBy => ({
  type: types.SET_SORT_BY,
  payload: { sortBy },
});

export const setOrgSortBy = sortBy => ({
  type: types.SET_ORG_SORT_BY,
  payload: { sortBy },
});

export const setView = view => ({
  type: types.SET_VIEW,
  payload: { view },
});

export const incrementPage = {
  type: types.INCREMENT_PAGE,
};

export const setPage = page => ({
  type: types.SET_PAGE,
  payload: { page },
});

export const setExternalSearch = filters => ({
  type: types.SET_EXTERNAL_SEARCH,
  payload: filters,
});

export const performExternalSearch = (request, geoRequest, filters) => ({
  type: types.PERFORM_EXTERNAL_SEARCH,
  payload: { request, geoRequest, filters },
});

export const setShowRefreshDialog = show => ({
  type: types.SET_SHOW_REFRESH_DIALOG,
  payload: show,
});

export const clearNextResults = {
  type: types.CLEAR_NEXT_RESULTS,
};
export const restoreState = state => ({
  type: types.RESTORE_STATE,
  payload: state,
});

export const setShowBuilder = show => ({
  type: types.SET_SHOW_BUILDER,
  payload: show,
});

export const setSearchBuilderFields = (type, terms, id) => ({
  type: types.SET_QUERY_BUILDER_FIELDS,
  payload: { type, terms, id },
});

export const clearSearchBuilderFields = id => ({
  type: types.CLEAR_SEARCH_BUILDER_FIELDS,
  payload: { id },
});

export const setMapRegion = region => ({
  type: types.SET_MAP_REGION,
  payload: { region },
});

export const setPdfExportStatus = exporting => ({
  type: types.SET_PDF_EXPORT_STATUS,
  payload: { exporting },
});

/**
 * @typedef {{title: string, sectionTitle}} FilterMetaData
 */

/**
 * @param {string} sectionKey
 * @param {boolean} isCollapsed
 * @returns {{sectionKey: string, isCollapsed: boolean}}
 */
export const setFilterSectionIsCollapsed = (sectionKey, isCollapsed) => ({
  type: types.SET_FILTER_SECTION_COLLAPSED,
  payload: { sectionKey, isCollapsed },
});

/**
 * @param {boolean} hasDismissedVCFundingSortModal
 * @returns {{hasDismissedVCFundingSortModal: boolean}}
 */
export const setHasDismissedVCFundingSortModal = hasDismissedVCFundingSortModal => ({
  type: types.SET_HAS_DISMISSED_VC_FUNDING_SORT_MODAL,
  payload: { hasDismissedVCFundingSortModal },
});

/**
 * @param {boolean} showVCFundingSortModal
 * @returns {{hasDismissedVCFundingSortModal: boolean}}
 */
export const setShowVCFundingSortModal = showVCFundingSortModal => ({
  type: types.SHOW_VC_FUNDING_SORT_MODAL,
  payload: { showVCFundingSortModal },
});

/**
 * @param {string} error
 * @returns {{error: string}}
 */
export const addMembersToListFromSearchError = error => ({
  type: types.ADD_MEMBERS_TO_LIST_FROM_SEARCH_ERROR,
  payload: { error },
});

export const resetAdddMembersToListFromSearchError = () => ({
  type: types.RESET_ADD_MEMBERS_TO_LIST_FROM_SEARCH_ERROR,
});
