import { batch, useDispatch } from 'react-redux';

import { siNumber } from 'utils/number';
import { appendFilter, setFilter } from 'actions/search';

import GraphBase from './GraphBase';

function SkipNameShorteningForWord(value , index){
  let skip = false;
  const AvoidShortening = [
    'SPIE'
  ];
  if((index === 0 && AvoidShortening.some(x => x === value)))
    skip = true;
  return skip;
}

export const generateShortTradeshowName = name =>
  name
    .split(' ')
    .filter((x , i) => !SkipNameShorteningForWord(x, i))
    .map(x => x[0])
    .join('');

const Tradeshows = ({ data, label, helpText }) => {
  const dispatch = useDispatch();

  const options = {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(value) {
              return siNumber(value, 1);
            },
            beginAtZero: true,
            precision: 0,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            callback: function(tradeshow) {
              if (tradeshow.length <= 6) return tradeshow;

              const shortMatches = tradeshow.match(/\((.*?)\)/);
              const lastShort = shortMatches
                ? shortMatches[shortMatches.length - 1]
                : generateShortTradeshowName(tradeshow);

              return lastShort;
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem) {
          return siNumber(tooltipItem.yLabel);
        },
      },
    },
    aspectRatio: 4,
  };

  const handleClick = tradeshow => {
    batch(() => {
      dispatch(appendFilter('tradeshow', { label: tradeshow }));
      dispatch(setFilter('presentingAtTradeshow', true));
    });
  };

  return (
    <GraphBase
      label={label}
      data={data}
      type='bar'
      options={options}
      onClick={handleClick}
      helpText={helpText}
      fullWidth={true}
    />
  );
};

export default Tradeshows;
