import { useSelector, useDispatch } from 'react-redux';

import { Tooltip } from 'components/common';
import { setFilters, appendFilter, setFilter } from 'actions/search';
import { getFilter, getActiveFilters } from 'selectors/search';
import { formatCurrency } from 'utils/number';
import {
  activityCodes,
  applicationTypes,
  instituteCodes,
} from 'services/inputTokens';

import { FilterSection } from './styled';
import { Filter, helpText } from '../helpers';
import { Container } from 'styled';
import FilterGroup from './FilterGroup';
import { fundingKeys } from './keys';

const Funding = () => {
  const dispatch = useDispatch();

  const nihOnlyKeys = [
    'nihApplicationType',
    'nihActivityCode',
    'nihInstituteCode',
  ];

  const activeFilters = useSelector(getActiveFilters(Object.keys(fundingKeys)));
  const disableEarlyAward = useSelector(getFilter('fundingStarts'));

  const handleCore = value => {
    dispatch(setFilter('core', value));
    dispatch(appendFilter('nihActivityCode', 'P30 - Center Core Grants'));
  };

  const handleActivityCodeChange = value => {
    if (value === null) {
      return null;
    }

    if (value.indexOf('P30 - Center Core Grants') >= 0) {
      dispatch(setFilters({ core: true }));
    } else {
      dispatch(setFilters({ core: false }));
    }

    dispatch(setFilter('nihActivityCode', value));
  };

  return (
    <FilterGroup
      icon='dollar'
      title='Funding'
      activeKeys={activeFilters}
      sectionKey='funding'
    >
      <>
        <Filter
          placeholder={['Awarded From', 'Awarded To']}
          type='range'
          filterType='datePicker'
          filterId={fundingKeys.dateAwarded.key}
        />
        <Filter
          filterType='number'
          filterId={fundingKeys.minFunding.key}
          formatter={formatCurrency}
          placeholder='Min. Funding (3 Years)'
          step={10000}
          min={0}
          helpText='Filter results by the minimum 3 year funding a researcher has received'
        />
        <Filter
          filterType='number'
          filterId={fundingKeys.maxFunding.key}
          formatter={formatCurrency}
          placeholder='Max. Funding (3 Years)'
          step={10000}
          min={0}
          helpText='Filter results by the maximum 3 year funding a researcher has received'
        />

        <Tooltip
          title={
            activeFilters.filter(x => x === 'earlyAwardDate').length > 0
              ? helpText.filterDisabled
              : helpText.grantExpiry
          }
          placement='right'
          overlayStyle={{ maxWidth: 450 }}
        >
          <Filter
            filterType='checkbox'
            filterId={fundingKeys.grantExpiry.key}
            disabled={
              activeFilters.filter(x => x === 'earlyAwardDate').length > 0
            }
          >
            Grants Expiring Within 3 Months
          </Filter>
        </Tooltip>
        {/*     <Filter
        filterType='token'
        placeholder='Funding Round'
        filterId='fundingRound'
        options={fundingRounds}
      />*/}
        <FilterSection
          showInactive={activeFilters.some(key => nihOnlyKeys.includes(key))}
        >
          NIH Only
        </FilterSection>
        <Filter
          filterType='token'
          placeholder='Application Type'
          filterId={fundingKeys.nihApplicationType.key}
          options={applicationTypes}
        />
        <Filter
          filterType='token'
          placeholder='Activity Code'
          filterId={fundingKeys.nihActivityCode.key}
          options={activityCodes}
          onChange={handleActivityCodeChange}
        />
        <Filter
          filterType='token'
          placeholder='Institute Code'
          filterId={fundingKeys.nihInstituteCode.key}
          options={instituteCodes}
        />
        <Container>
          <Filter
            filterType='checkbox'
            helpText={
              activeFilters.filter(x => x === 'grantExpiry').length > 0
                ? helpText.filterDisabled
                : helpText.earlyAwardDate
            }
            filterId={fundingKeys.earlyAwardDate.key}
            disabled={
              disableEarlyAward ||
              activeFilters.filter(x => x === 'grantExpiry').length > 0
            }
          >
            Early Budget
          </Filter>
          <Filter
            filterType='switch'
            filterId={fundingKeys.core.key}
            onChange={handleCore}
          >
            Core Labs Only
          </Filter>
        </Container>
      </>
    </FilterGroup>
  );
};

export default Funding;
