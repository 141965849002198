import { Button, Select } from 'components/common';

import { FooterContainer, FooterInfo } from '../styled';

const Footer = ({
  onChangePageSize,
  onChangePageNumber,
  pageSize,
  pageNumber,
  resultSize,
  rowCount,
  pageSizeOptions,
  footerStyle,
  footerActions,
  footerInfo,
  loading,
}) => {
  if (loading) return null;

  const startIndex = pageSize * pageNumber + 1;
  const endIndex = startIndex + (rowCount - 1);

  let biggest = false;
  const filteredPageSizes = pageSizeOptions.filter(o => {
    if (biggest) return false;
    if (+o.value >= resultSize) {
      biggest = true;
    }
    return true;
  });

  return (
    <FooterContainer style={footerStyle}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <span>{startIndex}</span> - <span>{endIndex}</span> of{' '}
          <span data-testid='table-footer-resultSize'>{resultSize}</span>
        </div>
        {filteredPageSizes.length > 1 && (
          <Select
            style={{ marginLeft: '10px' }}
            size='small'
            options={filteredPageSizes}
            value={pageSize}
            onChange={onChangePageSize}
          />
        )}
        {footerInfo && <FooterInfo>{footerInfo}</FooterInfo>}
        {footerActions}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          size='small'
          disabled={pageNumber === 0}
          onClick={() => onChangePageNumber(pageNumber - 1)}
        >
          Previous Page
        </Button>
        <Button
          style={{ marginLeft: '10px' }}
          size='small'
          disabled={endIndex === resultSize}
          onClick={() => onChangePageNumber(pageNumber + 1)}
        >
          Next Page
        </Button>
      </div>
    </FooterContainer>
  );
};

export default Footer;
