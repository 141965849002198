import { Icon, Link } from 'components/common';
import { colours } from 'utils/theme';

const IconText = ({
  value,
  icon,
  color,
  className,
  iconSize,
  link,
  style,
  textStyle,
  onClick,
  internalLink,
}) => {
  if (!value) return null;
  const valueToDisplay = link ? (
    <Link to={link} external={!internalLink} showIconHint={internalLink}>
      {value}
    </Link>
  ) : (
    value
  );

  const handleClick = e => {
    e.stopPropagation();

    onClick();
  };

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', ...style }}
      className={className}
      onClick={e => (link ? handleClick : null)}
    >
      <div
        style={{
          minWidth: 15,
          width: 15,
          display: 'flex',
          justifyContent: 'center',
          marginRight: 5,
        }}
        className='icon'
      >
        <Icon icon={icon} size={iconSize} color={color} />
      </div>
      <div style={{ ...textStyle }}>{valueToDisplay}</div>
    </div>
  );
};

IconText.defaultProps = {
  color: colours.teal,
  iconSize: 'sm',
};

export default IconText;
