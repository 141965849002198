import { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { Pane as CommonPane } from 'components/common';
import { log } from 'services/api';

const Pane = styled(CommonPane)`
  .content {
    font-size: 13px;
  }
`;

class ErrorBoundary extends Component {
  state = { hasError: false, error: null, errorInfo: null };

  componentDidCatch(error, errorInfo) {
    fetch(log, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        description: `Route ${
          this.props.location.pathname
        } - ${error.toString()} - v${this.props.version}`,
        action: 'UIError',
        outcome: 'Failure',
      }),
    });
    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      this.state.hasError
    ) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError)
      return (
        <Pane title='Whoops!' style={{ fontSize: 13 }}>
          <p>
            Something went wrong while processing your request, please refresh
            the page and try again.
          </p>
          <p>
            The issue has been reported to our team, but if you continue to
            receive this error please reach out to us via live chat.
          </p>
        </Pane>
      );
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
