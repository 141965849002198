import React from 'react';
import GraphBase from './GraphBase';
import { generateShortTradeshowName } from './Tradeshows';
import { batch, useDispatch } from 'react-redux';
import { appendFilter, setFilter } from 'actions/search';

export default function OrgExhibitors({ data }) {
  const dispatch = useDispatch();

  let options = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            callback: tradeshow => {
              if (tradeshow.length <= 6) return tradeshow;

              const shortMatches = tradeshow.match(/\((.*?)\)/);
              const lastShort = shortMatches
                ? shortMatches[shortMatches.length - 1]
                : generateShortTradeshowName(tradeshow);

              return lastShort;
            },
          },
        },
      ],
    },
  };

  const handleClick = tradeshow => {
    batch(() => {
      dispatch(appendFilter('tradeshow', { label: tradeshow }));
      dispatch(setFilter('presentingAtTradeshow', false));
      dispatch(setFilter('exhibitingShow', true));
    });
  };

  return (
    <>
      <GraphBase
        type='horizontalBar'
        label='Organisation Exhibitions'
        data={data}
        options={options}
        helpText='Tradeshows where organisations in your results most frequently exhibit. (This chart includes exhibitions across all time)'
        fullWidth={false}
        onClick={handleClick}
      />
    </>
  );
}
