import { Fragment, useMemo } from 'react';

import {
  Table,
  Link,
  Clamp,
  PublicationsFooter,
  // PublicationAuthors,
} from 'components/common';
import { format } from 'utils/date';
import { formatCurrency } from 'utils/number';
import licenseType from 'models/licenseType';
import { useAuth } from 'hooks';

const perPage = [
  { value: 5, label: '5 per page' },
  { value: 10, label: '10 per page' },
  { value: 25, label: '25 per page' },
  { value: 50, label: '50 per page' },
];

// const formatAuthorName = author => {
//   if (author.collectiveName)
//     return (
//       <div
//         title={author.collectiveName}
//         style={{
//           whiteSpace: 'nowrap',
//           textOverflow: 'ellipsis',
//           overflow: 'hidden',
//         }}
//       >
//         {author.collectiveName}
//       </div>
//     );
//   return `${author.lastName} ${author.initials}`;
// };

export const BuildConfig = data => {
  const config = {};
  const { clinicalTrials, funding, posters, publications } = data;
  const { hasLicense } = useAuth();
  const hasIndustryLicense = useMemo(
    () => hasLicense([licenseType.industry], true),
    [hasLicense]
  );

  const configs = {
    publications: {
      headers: [
        'Publication Title',
        // 'Authors',
        'Journal/Preprints',
        'Date Published',
        'Citations',
      ],
      createRow: (r, i) => (
        <Fragment key={`${r.citationId}-${i}`}>
          <div>
            <Link to={`publication/${r.citationId}`}>
              <Clamp text={r.articleTitle} />
            </Link>
          </div>
          {/* <PublicationAuthors
              authors={r.authors}
              authorCount={r.authors.length}
              formatName={formatAuthorName}
              profileIdKey='scileadsSuperResearcherId'
            /> */}
          <Clamp text={r.journalTitle} />
          <div>{format(r.date)}</div>
          <div>{r.cited}</div>
        </Fragment>
      ),
    },
    posters: {
      headers: ['Tradeshow', 'Year', 'Title', 'Session Type'],
      createRow: (r, i) => (
        <Fragment key={`${r.sciLeadsTradeShowId}-${r.sciLeadsPosterId}-${i}`}>
          <Clamp text={r.show} />
          <div>{r.year}</div>
          <Link to={`/poster/${r.sciLeadsPosterId}`}>
            <Clamp text={r.title} />
          </Link>
          <div>{r.sessionType !== 'Uncategorised' ? r.sessionType : '-'}</div>
        </Fragment>
      ),
    },
    funding: {
      headers: [
        'Funded Project Title',
        'Date Awarded',
        { label: 'Funding (USD)', style: { justifyContent: 'flex-end' } },
        'Funding Starts',
        'Funding Ends',
        'Description',
      ],
      createRow: (r, i) => (
        <Fragment key={`${r.sciLeadsAwardId}`}>
          <div>
            <Link to={`/project/${r.sciLeadsAwardId}`}>
              <Clamp text={r.title} />
            </Link>
          </div>
          <div>{format(r.dateAwarded)}</div>
          <div style={{ justifyContent: 'flex-end' }}>
            {r.fundingAmountUsd
              ? `$${formatCurrency(r.fundingAmountUsd)}`
              : '-'}
          </div>
          <div>{format(r.fundingStarts)}</div>
          <div>{format(r.fundingEnds)}</div>
          <Clamp lines={2} text={r.description} />
        </Fragment>
      ),
    },
    clinicalTrials: {
      headers: [
        'NCT ID',
        'Role',
        'First Posted',
        'Study Type',
        'Title',
        'Status',
        'Phase',
        'Sponsor',
      ],
      createRow: (r, i) => (
        <Fragment key={r.nctId}>
          <div>
            <Link to={`/trial/${r.nctId}`}>{r.nctId}</Link>
          </div>
          <div>{r.researcherRole}</div>
          <div>{format(r.date)}</div>
          <div>{r.studyType}</div>
          <Clamp text={r.title} />
          <div>{r.overallStatus}</div>
          <div>{r.phase}</div>
          {!hasIndustryLicense && <div>{r.leadSponsor}</div>}
          {hasIndustryLicense && r.leadSponsorSuperOrg && (
            <Link to={`/organisation/${r.leadSponsorSuperOrg}`}>
              {r.leadSponsor}
            </Link>
          )}
          {hasIndustryLicense && !r.leadSponsorSuperOrg && (
            <div>{r.leadSponsor}</div>
          )}
        </Fragment>
      ),
    },
    researchers: {
      headers: ['Name', 'Email'],
      createRow: (r, i) => (
        <Fragment key={`${r.id}-${i}`}>
          <div>
            <Link to={`/researcher/${r.id}`}>{r.researcherFullName}</Link>
          </div>
          <div>{r.researcherEmail}</div>
        </Fragment>
      ),
    },
  };

  if (funding && funding.length) {
    config.funding = {
      icon: 'award',
      content: (
        <Table
          key='funding-table'
          pageSizeOptions={perPage}
          config={configs.funding}
          data={funding}
          footerStyle={{ padding: '15px' }}
          paginate
        />
      ),
    };
  }
  if (posters && posters.length) {
    config.posters = {
      icon: 'image',
      content: (
        <Table
          pageSizeOptions={perPage}
          config={configs.posters}
          data={posters}
          footerStyle={{ padding: '15px' }}
          paginate
        />
      ),
    };
  }
  if (publications && publications.length) {
    config.publications = {
      icon: 'book',
      content: (
        <Table
          pageSizeOptions={perPage}
          config={configs.publications}
          data={publications}
          footerStyle={{ padding: '15px' }}
          footerInfo={
            <PublicationsFooter
              firstNamedCount={data.totalFirstNamedAuthorCount}
              lastNamedCount={data.totalLastNamedAuthorCount}
              totalCollabs={data.totalCollaboratorsOverall}
              impactAll={data.allTimeHIndexAverage}
              impactThree={data.threeYearHIndexAverage}
            />
          }
          paginate
        />
      ),
    };
  }
  if (clinicalTrials && clinicalTrials.length) {
    config.clinicalTrials = {
      icon: 'trial',
      content: (
        <Table
          pageSizeOptions={perPage}
          config={configs.clinicalTrials}
          data={clinicalTrials}
          footerStyle={{ padding: '15px' }}
          paginate
        />
      ),
    };
  }

  return config;
};
