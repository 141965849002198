import { notification } from 'antd';

export const ewrcaNotification = {
  message: 'Cannot Process',
  description:
    'This export is too large to process. Please reduce the activity count (this comprises the total number of Funding Awards, Tradeshow Sessions, Publication Articles and Clinical Trials) to below a combined 50K and emails to below 500 third party emails.',
  placement: 'bottomLeft',
};

export const exportNotification = largeExport => ({
  message: 'Generating Report',
  description: `We are generating your report and will notify you ${
    largeExport ? 'via email' : ''
  } when it is ready.`,
  placement: 'bottomLeft',
});

const displayableSearchErrors = ['Search.ExceededSearchTermsLimit'];

function getWhoopsDescription(error) {
  if (error?.errors) {
    const errorsToDisplay = displayableSearchErrors
      .filter(err => error.errors[err])
      .map(err => error.errors[err]);
    if (errorsToDisplay.length > 0) {
      return errorsToDisplay[0];
    }
  }

  if (error) {
    if (displayableSearchErrors.includes(error.statusText)) {
      return error.message;
    }
  }

  return (
    <>
      There was an issue with your search. This is commonly caused by a typo in
      your search term. Please review your query and try again.
      <br />
      <br />
      If your problem persists, you can contact us via live chat in the bottom
      right.
    </>
  );
}

export const handleSearchError = error => {
  if (error.status === 429) {
    notification.open({
      message: 'Trial Limit Reached',
      description: (
        <div>
          You have reached the daily search limit on your free trial.
          <br />
          <br />
          To enable unlimited searches, speak to our sales team about upgrading
          your account.
        </div>
      ),
      placement: 'bottomLeft',
    });
  } else {
    notification.open({
      message: 'Whoops!',
      description: (
        <div style={{ whiteSpace: 'pre-line' }}>
          {getWhoopsDescription(error)}
        </div>
      ),
      placement: 'bottomLeft',
    });
  }
};

export const handleExportNotification = (ewrca, largeExport) => {
  notification.open(
    ewrca ? ewrcaNotification : exportNotification(largeExport)
  );
};
