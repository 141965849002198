import { useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Pane, Form, Button } from 'components/common';
import { performSearch, clearFilters, setFilter } from 'actions/purchasing';
import { getFilter, SLICE, getSearchRequest } from 'selectors/purchasing';

import Filter from './helpers/FilterField';
import { Actions, Buttons, SearchBar } from './styled';
import { PurchasingContext } from './';
import { performBoolSearch, performPhraseSearch } from 'selectors/users';
import BoolErrorMessage from 'components/Search/BoolErrorMessage';
import { SearchDiv } from 'components/Item/styled';

const SearchArea = () => {
  const postSearch = useContext(PurchasingContext);
  const dispatch = useDispatch();

  const searchTermRef = useRef();
  const [showBoolError, setShowBoolError] = useState(false);
  const getHasBoolError = useSelector(performBoolSearch(SLICE));
  const getHasPhraseError = useSelector(performPhraseSearch(SLICE));
  const { loading } = useSelector(getSearchRequest);

  const handleSearch = () => {
    if (showBoolError !== null && (getHasBoolError || getHasPhraseError)) {
      setShowBoolError(true);
    } else {
      handlePerformSearch();
    }
  };

  const handlePerformSearch = () => {
    dispatch(performSearch(postSearch, { newSearch: true }));
  };

  const updateSearch = value => {
    dispatch(setFilter('term', value));
  };

  const handleClear = () => {
    dispatch(clearFilters);
  };

  const searchButton = (
    <Button
      type='primary'
      data-testid='search-button'
      onClick={handleSearch}
      size={'large'}
      disabled={loading}
    >
      Search
    </Button>
  );

  return (
    <Pane title='Purchasing Search'>
      <SearchBar>
        <SearchDiv ref={searchTermRef}>
          <Form style={{ flex: 1 }} width='100%' onSubmit={handleSearch}>
            <Filter
              filterType='input'
              placeholder='Enter a search term'
              filterId='term'
              size='large'
            />
          </Form>
          {showBoolError ? (
            <BoolErrorMessage
              updateSearchQuery={value => updateSearch(value)}
              performSearch={handlePerformSearch}
              hasBoolFailed={getHasBoolError}
              hasParseFailed={getHasPhraseError}
              term={getFilter('term')}
              searchTermRef={searchTermRef}
              setShowBoolError={setShowBoolError}
            />
          ) : (
            <></>
          )}
        </SearchDiv>
        <Actions>
          <Buttons>
            <Button
              type='secondary'
              data-testid='clear-search-button'
              onClick={handleClear}
              size={'large'}
              disabled={loading}
            >
              Clear
            </Button>
            {searchButton}
          </Buttons>
        </Actions>
      </SearchBar>
    </Pane>
  );
};

export default SearchArea;
