import { useCallback, useEffect } from 'react';
import { log as logUrl } from 'services/api';

export const outcomes = {
  success: 'Success',
};

const useLog = (description, action, outcome, onMount = true) => {
  const postLog = useCallback(payload => {
    fetch(logUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  }, []);

  useEffect(() => {
    if (onMount) {
      postLog({
        description,
        action,
        outcome,
      });
    }
  }, [onMount, postLog, description, action, outcome]);

  return useCallback(
    logPayload => {
      const payload = logPayload || {
        description,
        action,
        outcome,
      };
      postLog(payload);
    },
    [action, description, outcome, postLog]
  );
};

export default useLog;
