import { forwardRef } from 'react';
import { InputNumber as AntInputNumber, Tooltip } from 'antd';

const InputNumber = forwardRef(
  ({ onChange, helpText, value, placeholder, style = {}, ...rest }, ref) => {
    const handleOnChange = value => {
      onChange(value);
    };

    return (
      <Tooltip title={value ? placeholder : helpText} placement='right'>
        <AntInputNumber
          data-testid='input'
          {...rest}
          value={value}
          placeholder={placeholder}
          style={{ width: '100%', ...style }}
          onChange={handleOnChange}
          ref={ref}
        />
      </Tooltip>
    );
  }
);

export default InputNumber;
