import React from 'react';
import { Select as AntSelect } from 'antd';
import { OptionContainer, ListOption } from 'components/Lists/Actions/styled';
import { Tooltip } from 'antd';
import TooltipContent from '../TooltipContent';

const { Option: AntOption } = AntSelect;

const listRenderOption = ({ value, label, description }, i) => {
  return (
    <AntOption
      key={`${value}-${i}`}
      value={value}
      name={label}
      description={description}
    >
      <OptionContainer>
        <Tooltip
          title={<TooltipContent label={label} description={description} />}
          placement='leftBottom'
          overlayStyle={{ maxWidth: '650px' }}
        >
          <ListOption>{label}</ListOption>
        </Tooltip>
      </OptionContainer>
    </AntOption>
  );
};

export default listRenderOption;
