import {
  setClinicalTrialPageSize,
  setClinicalTrialPageNumber,
  setClinicalTrialSort,
} from 'actions/organisation';
import { Link, Loader, Table } from 'components/common';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getOrganisationClinicalTrialData,
  getOrganisationLoadingClinicalTrialData,
  getOrganisationClinicalTrialPageNumber,
  getOrganisationClinicalTrialPageSize,
  getOrganisationClinicalTrialSort,
} from 'selectors/organisation';
import { format } from 'utils/date';
import ClinicalTrialNotice from './ClinicalTrialNotice';

const OrganisationClinicalTrials = ({
  organisation,
  handleSearch,
  loading,
}) => {
  const dispatch = useDispatch();
  const sort = useSelector(getOrganisationClinicalTrialSort(organisation.id));
  const pageNumber = useSelector(
    getOrganisationClinicalTrialPageNumber(organisation.id)
  );
  const pageSize = useSelector(
    getOrganisationClinicalTrialPageSize(organisation.id)
  );

  const isLoading =
    useSelector(getOrganisationLoadingClinicalTrialData(organisation.id)) ||
    loading;

  const organisationClinicalTrialData = useSelector(
    getOrganisationClinicalTrialData(organisation.id)
  );

  const showLoader = isLoading || !organisationClinicalTrialData;

  const handleSortChange = sortKey => {
    dispatch(
      setClinicalTrialSort(organisation.id, {
        field: sortKey,
        direction: !sort
          ? 'asc'
          : sortKey === !sort.field
          ? 'asc'
          : sort.direction === 'asc'
          ? 'desc'
          : 'asc',
      })
    );
    handleSearch();
  };

  const handlePageSize = size => {
    dispatch(setClinicalTrialPageSize(organisation.id, size));
    handleSearch();
  };

  const handlePageNumber = number => {
    dispatch(setClinicalTrialPageNumber(organisation.id, number));
    handleSearch();
  };

  const perPage = [
    { value: 10, label: '10 per page' },
    { value: 25, label: '25 per page' },
    { value: 50, label: '50 per page' },
  ];

  const config = {
    headers: [
      {
        key: 'NctId.keyword',
        label: 'NCT ID',
        minWidth: 100,
        maxContentWidth: 120,
      },
      {
        key: 'Title.keyword',
        label: 'Title',
        minWidth: 200,
        maxContentWidth: 450,
      },
      {
        key: 'FirstPostedDate',
        label: 'First Posted Date',
        minWidth: 100,
        maxContentWidth: 200,
      },
      {
        key: 'OverallStatus',
        label: 'Status',
        minWidth: 100,
        maxContentWidth: 200,
      },
      {
        key: 'Phase',
        label: 'Phase',
        minWidth: 100,
        maxContentWidth: 200,
      },
      {
        label: 'Lead Sponsor',
        minWidth: 100,
        maxContentWidth: 200,
      },
    ],
    createRow: r => {
      return (
        <Fragment key={r.nctId}>
          <Link to={`/trial/${r.nctId}`}>{r.nctId}</Link>
          <div>{r.title}</div>
          <div>{format(r.firstPostedDate)}</div>
          <div>{r.overallStatus}</div>
          <div>{r.phase}</div>
          <div>{r.leadSponsorSuperOrg === organisation.id ? 'Yes' : 'No'}</div>
        </Fragment>
      );
    },
  };

  if (showLoader) return <Loader />;

  return (
    <>
      <ClinicalTrialNotice />
      <Table
        config={config}
        data={organisationClinicalTrialData?.items || []}
        loading={isLoading}
        onChangePageNumber={handlePageNumber}
        onChangePageSize={handlePageSize}
        pageSize={pageSize ?? 50}
        pageNumber={pageNumber ?? 0}
        resultSize={
          organisationClinicalTrialData?.distinctClinicalTrialsCount || 0
        }
        onSortChange={handleSortChange}
        paginate
        sortable
        className={'researchers-table'}
        sort={sort?.field}
        sortDir={sort?.direction}
        pageSizeOptions={perPage}
      />
    </>
  );
};

export default OrganisationClinicalTrials;
