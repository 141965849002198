//@ts-check
import { searchOrigin } from '../Results/constants';

export const hiddenKeys = Object.freeze({
  date: {
    key: 'date',
    description: 'Date',
  },
  organisationCategory: {
    key: 'organisationCategory',
    description: 'Organisation Category',
  },
});

export const categoryKeys = Object.freeze({
  funding: { key: 'funding', description: 'Funding' },
  tradeshows: { key: 'tradeshows', description: 'Tradeshows' },
  clinicalTrials: { key: 'clinicalTrials', description: 'Clinical Trials' },
  publications: { key: 'publications', description: 'Publications' },
  profile: { key: 'profile', description: 'Profile' },
});

export const listsKeys = Object.freeze({
  lists: {
    key: 'lists',
    description: 'Included',
    sectionTitle: 'Researcher Lists',
  },
  excludeLists: {
    key: 'excludeLists',
    description: 'Excluded',
    sectionTitle: 'Researcher Lists',
  },
  organisationLists: {
    key: 'organisationLists',
    description: 'Included',
    sectionTitle: 'Organisation Lists',
  },
  organisationExcludeLists: {
    key: 'organisationExcludeLists',
    description: 'Excluded',
    sectionTitle: 'Organisation Lists',
  },
});

export const advancedKeys = Object.freeze({
  totalPublications: {
    key: 'totalPublications',
    description: 'Min. Publications',
  },
  totalCollaboratorsOverall: {
    key: 'totalCollaboratorsOverall',
    description: 'Min. Collaborators',
  },
  totalFirstNamedAuthorCount: {
    key: 'totalFirstNamedAuthorCount',
    description: 'First Author Positions',
  },
  totalLastNamedAuthorCount: {
    key: 'totalLastNamedAuthorCount',
    description: 'Last Author Positions',
  },
  totalGrants: { key: 'totalGrants', description: 'Min. Total Grants' },
  firstAuthor: { key: 'firstAuthor', description: 'First Named Author' },
  middleAuthor: { key: 'middleAuthor', description: 'Middle Named Author' },
  lastAuthor: { key: 'lastAuthor', description: 'Last Named Author' },
  threeYearHIndexAverage: {
    key: 'threeYearHIndexAverage',
    description: 'Min. Research Impact (3 Year Avg)',
  },
  kol: { key: 'kol', description: 'Key Opinion Leaders Only' },
  journal: { key: 'journal', description: 'Journals or Preprint Repository' },
});

export const fundingKeys = Object.freeze({
  dateAwarded: { key: 'dateAwarded', description: 'Funding Awarded' },
  nihApplicationType: {
    key: 'nihApplicationType',
    description: 'Application Type',
  },
  nihActivityCode: { key: 'nihActivityCode', description: 'Activity Code' },
  nihInstituteCode: { key: 'nihInstituteCode', description: 'Institute Code' },
  earlyAwardDate: { key: 'earlyAwardDate', description: 'Early Budget' },
  maxFunding: { key: 'maxFunding', description: 'Max. Funding (3 Years)' },
  minFunding: { key: 'minFunding', description: 'Min. Funding (3 Years)' },
  grantExpiry: {
    key: 'grantExpiry',
    description: 'Grants Expiring Within 3 Months',
  },
  core: { key: 'core', description: 'Core Labs Only' },
});

export const geoResearcherKeys = Object.freeze({
  organisationGeography: {
    key: 'organisationGeography',
    description: 'Region/Country',
  },
  city: { key: 'city', description: 'City' },
  organisationName: { key: 'organisationName', description: 'Organisation' },
  state: { key: 'state', description: 'State' },
  postcode: { key: 'postcode', description: 'Zip / Postcodes' },
  email: { key: 'email', description: 'Email Domains - Max 10' },
  lastName: { key: 'lastName', description: 'Last Name' },
  jobTitle: { key: 'jobTitle', description: 'Job title' },
  recentlyMovedOrg: {
    key: 'recentlyMovedOrg',
    description: 'Moved organisation within last six months',
  },
  organisationDescription: {
    key: 'organisationDescription',
    description: 'Organisation Description',
  },
});

export const geoOrganisationKeys = Object.freeze({
  organisationGeography: {
    key: 'organisationGeography',
    description: 'Region/Country',
  },
  city: { key: 'city', description: 'City' },
  organisationName: { key: 'organisationName', description: 'Organisation' },
  state: { key: 'state', description: 'State' },
  postcode: { key: 'postcode', description: 'Zip / Postcodes' },
  email: { key: 'email', description: 'Email Domains - Max 10' },
  lastName: { key: 'lastName', description: 'Last Name' },
  jobTitle: { key: 'jobTitle', description: 'Job title' },
  recentlyMovedOrg: {
    key: 'recentlyMovedOrg',
    description: 'Moved organisation within last six months',
  },
  superOrganisationSize: {
    key: 'superOrganisationSize',
    description: 'Employee Count',
  },
  superOrganisationFoundedYear: {
    key: 'superOrganisationFoundedYear',
    description: 'Year Founded',
  },
  organisationDescription: {
    key: 'organisationDescription',
    description: 'Organisation Description',
  },
  recentlyAddedOrg: {
    key: 'recentlyAddedOrg',
    description: 'Added within last month',
  },
});

export const tradeshowResearcherKeys = Object.freeze({
  tradeshow: { key: 'tradeshow', description: 'Tradeshow' },
  thisShowOnly: {
    key: 'thisShowOnly',
    description: 'Search selected tradeshows only',
  },
  sessionType: { key: 'sessionType', description: 'Tradeshow Session Type' },
  thisSessionTypeOnly: {
    key: 'thisSessionTypeOnly',
    description: 'Only show results with selected session types',
  },
});

export const tradeshowOrganisationKeys = Object.freeze({
  tradeshow: { key: 'tradeshow', description: 'Tradeshow' },
  thisShowOnly: {
    key: 'thisShowOnly',
    description: 'Search selected tradeshows only',
  },
  exhibitingShow: {
    key: 'exhibitingShow',
    description: 'Exhibiting at a tradeshow',
  },
  presentingAtTradeshow: {
    key: 'presentingAtTradeshow',
    description: 'Presenting at a tradeshow',
  },
});

export const trialKeys = Object.freeze({
  phase: { key: 'phase', description: 'Phase' },
  overallStatus: { key: 'overallStatus', description: 'Status' },
  leadSponsor: { key: 'leadSponsor', description: 'Lead Sponsor' },
  sponsors: { key: 'sponsors', description: 'All Sponsors' },
  roles: { key: 'roles', description: 'Role' },
  startDate: { key: 'startDate', description: 'Clinical Trial Start' },
  hasClinicalTrials: {
    key: 'hasClinicalTrials',
    description: 'Listed on at least one clinical trial',
  },
  minSponsoredTrials: {
    key: 'minSponsoredTrials',
    description: 'Is a sponsor on Min number of trials',
  },
  phaseStartDate: {
    key: 'phaseStartDate',
    description: 'Phase Start Date',
  },
});

export const ventureCapitalKeys = Object.freeze({
  latestFundingRoundsOnly: {
    key: 'latestFundingRoundsOnly',
    description: 'Only Search the Companys latest funding Round',
  },
  fundingType: {
    key: 'fundingType',
    getDescription: filters =>
      filters[ventureCapitalKeys.latestFundingRoundsOnly.key]
        ? 'Latest Funding Round'
        : 'Funding Round',
  },
  announcedDateSelection: {
    key: 'announcedDateSelection',
    getDescription: filters =>
      filters[ventureCapitalKeys.latestFundingRoundsOnly.key]
        ? 'Latest Announced Date'
        : 'Announced Date',
  },
  announcedDatePicked: {
    key: 'announcedDatePicked',
    description: 'VC Funding Announced',
  },
  minFundingAmount: {
    key: 'minFundingAmount',
    getDescription: filters =>
      filters[ventureCapitalKeys.latestFundingRoundsOnly.key]
        ? 'Latest Min Funding Amount ($)'
        : 'Funding Round Min Amount ($)',
  },
  maxFundingAmount: {
    key: 'maxFundingAmount',
    getDescription: filters =>
      filters[ventureCapitalKeys.latestFundingRoundsOnly.key]
        ? 'Latest Max Funding Amount ($)'
        : 'Funding Round Max Amount ($)',
  },
  investors: {
    key: 'investors',
    getDescription: filters =>
      filters[ventureCapitalKeys.latestFundingRoundsOnly.key]
        ? 'Lastest Investors'
        : 'Investors',
  },
  minTotalFundingRaised: {
    key: 'minTotalFundingRaised',
    description: 'Total Funding Raised Min Amount ($)',
  },
  maxTotalFundingRaised: {
    key: 'maxTotalFundingRaised',
    description: 'Total Funding Raised Max Amount ($)',
  },
});

export const acquisitionOnlyKeys = Object.freeze({
  showAcquiredCompanies: {
    key: 'showAcquiredCompanies',
    description: 'Show Acquired Companies',
  },
  showAcquiringCompanies: {
    key: 'showAcquiringCompanies',
    description: 'Show Companies that Acquire',
  },
  acquirers: { key: 'acquirers', description: 'Acquirer' },
  acquisitionType: { key: 'acquisitionType', description: 'Acquisition Type' },
  minAcquisitionPrice: {
    key: 'minAcquisitionPrice',
    description: 'Acquisition Min Amount ($)',
  },
  maxAcquisitionPrice: {
    key: 'maxAcquisitionPrice',
    description: 'Acquisition Max Amount ($)',
  },
  acquisitionDatePicked: {
    key: 'acquisitionDatePicked',
    description: 'Acquisition Date Range',
  },
  acquisitionDateSelection: {
    key: 'acquisitionDateSelection',
    description: 'Acquisition Date',
  },
});

export const groupedKeys = {
  category: [
    categoryKeys.clinicalTrials.key,
    categoryKeys.funding.key,
    categoryKeys.profile.key,
    categoryKeys.publications.key,
    categoryKeys.tradeshows.key,
  ],
  namedAuthor: [
    advancedKeys.firstAuthor.key,
    advancedKeys.middleAuthor.key,
    advancedKeys.lastAuthor.key,
  ],
};

export const keysPerView = {
  [searchOrigin.researcherSearch]: [
    categoryKeys,
    hiddenKeys,
    geoResearcherKeys,
    fundingKeys,
    tradeshowResearcherKeys,
    trialKeys,
    listsKeys,
    advancedKeys,
  ],
  [searchOrigin.organisationSearch]: [
    categoryKeys,
    hiddenKeys,
    geoOrganisationKeys,
    fundingKeys,
    ventureCapitalKeys,
    acquisitionOnlyKeys,
    tradeshowOrganisationKeys,
    trialKeys,
    listsKeys,
    advancedKeys,
  ],
};

/**
 * @param {string} key
 * @returns {string}
 */
export function getSectionTitleFromKey(key) {
  if (categoryKeys[key]) return 'Category';
  if (advancedKeys[key]) return 'Advanced / KOL';
  if (fundingKeys[key]) return 'Funding';
  if (geoResearcherKeys[key] || geoOrganisationKeys[key])
    return 'Organisation / Geography';
  if (tradeshowResearcherKeys[key] || tradeshowOrganisationKeys[key])
    return 'Tradeshows';
  if (trialKeys[key]) return 'Clinical Trials';
  if (ventureCapitalKeys[key]) return 'Venture Capital';
  if (acquisitionOnlyKeys[key]) return 'Acquisitions';
  if (listsKeys[key]) return 'Lists';

  return '';
}

export const dateFields = [
  { datePicker: hiddenKeys.date.key },
  {
    datePicker: acquisitionOnlyKeys.acquisitionDateSelection.key,
    dateSelection: acquisitionOnlyKeys.acquisitionDatePicked.key,
  },
  {
    datePicker: ventureCapitalKeys.announcedDateSelection.key,
    dateSelection: ventureCapitalKeys.announcedDatePicked.key,
  },
];

const searchKeys = (() => {
  let ret = {};
  for (let view of [
    searchOrigin.organisationSearch,
    searchOrigin.researcherSearch,
  ]) {
    ret[view] = {};
    keysPerView[view].forEach(x => {
      Object.values(x).forEach(y => {
        let isGrouped = Object.keys(groupedKeys).filter(z =>
          groupedKeys[z].includes(y.key)
        );
        let field = y.key;
        let filterGroup = isGrouped?.length > 0 ? isGrouped[0] : '';
        if (filterGroup) {
          field = `${filterGroup}.${field}`;
        }
        ret[view][field] = {
          title: y.description,
          sectionTitle: y.sectionTitle
            ? y.sectionTitle
            : getSectionTitleFromKey(y.key),
          getTitle: y.getDescription ? z => y.getDescription(z) : null,
        };
      });
    });
  }
  return ret;
})();

export function GetSearchKeysByView(view) {
  return searchKeys[view];
}

export function RemoveNonEmptyDate(filters) {
  let filterKeys = Object.keys(filters);
  let dateKeys = dateFields.filter(x => {
    if (filterKeys.includes(x.datePicker)) return true;
    else return false;
  });
  if (dateKeys) {
    dateKeys.forEach(x => {
      if (
        filters[x.datePicker] === 'all' ||
        (x.dateSelection
          ? filters[x.datePicker] === 'custom' && !filters[x.dateSelection]
          : filters[x.datePicker] === 'custom')
      ) {
        delete filters[x.datePicker];
      }
    });
  }
}
