import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ScrollableMenu } from './styled';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

const YearsMenu = ({
  years,
  month,
  year,
  dateRange,
  onYearSelect,
  onMonthSelect,
  onCustomSelect,
  loading,
}) => {
  const [selectedYear, setSelectedYear] = useState(year);
  const [selectedMonth, setSelectedMonth] = useState(month);
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);
  const selectedItemRef = useRef(null);

  const getButtonText = useCallback(() => {
    if (dateRange) return 'Custom';
    if (selectedMonth && selectedYear) {
      return `${selectedMonth}, ${selectedYear}`;
    } else if (selectedYear) {
      return selectedYear;
    } else {
      return 'Select Year';
    }
  }, [dateRange, selectedMonth, selectedYear]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    if (menuVisible && selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [menuVisible]);

  const handleYearSelect = year => {
    setSelectedYear(year);
    setSelectedMonth(null);
    setMenuVisible(false);
    if (year === 'Custom') {
      onCustomSelect();
    } else {
      onYearSelect(year);
    }
  };

  const handleMonthSelect = (year, month) => {
    setSelectedYear(year);
    setSelectedMonth(month);
    setMenuVisible(false);
    onMonthSelect(year, month);
  };

  const items = [
    {
      key: 'Custom',
      label: 'Custom',
      onClick: () => handleYearSelect('Custom'),
    },
    ...(years
      ? years.map(year => ({
          key: year,
          label: (
            <div
              ref={selectedYear === year ? selectedItemRef : null}
              onClick={() => handleYearSelect(year)}
            >
              {year}
            </div>
          ),
          children: months.map(month => ({
            key: `${year}-${month}`,
            label: (
              <div
                ref={
                  selectedYear === year && selectedMonth === month
                    ? selectedItemRef
                    : null
                }
                onClick={() => handleMonthSelect(year, month)}
              >
                {month}
              </div>
            ),
          })),
        }))
      : []),
  ];

  const selectedKeys =
    selectedMonth && selectedYear
      ? [`${selectedYear}-${selectedMonth}`]
      : selectedYear
      ? [`${selectedYear}`]
      : [];

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      ref={menuRef}
    >
      <Button onClick={() => setMenuVisible(!menuVisible)} disabled={loading}>
        {getButtonText()} <DownOutlined />
      </Button>
      {menuVisible && (
        <div
          style={{
            position: 'absolute',
            zIndex: 1002,
            background: 'white',
            border: '1px solid #d9d9d9',
          }}
        >
          <ScrollableMenu
            items={items}
            mode='vertical'
            selectedKeys={selectedKeys}
            getPopupContainer={node => node.parentNode}
          />
        </div>
      )}
    </div>
  );
};

export default YearsMenu;
