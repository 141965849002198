import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'components/common';
import styled from 'styled-components';

const CategoryButton = styled(Button)`
  border: 2px solid ${({ value }) => (value ? '#6298fd' : '#e4e4e4')};
  border-radius: 4px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: ${({ value }) => (value ? '#6298fd' : '#a0a0a0')};
  }

  span {
    margin-left: 5px;
    color: ${({ value }) => (value ? '#6298fd' : '#a0a0a0')};
  }
`;

const Category = ({ helpText, onChange, icon, value, ...rest }) => {
  const handleChange = () => {
    if (onChange) {
      onChange(!value);
    }
  };

  return (
    <CategoryButton
      size='small'
      value={value}
      onClick={handleChange}
      aria-pressed={value}
      {...rest}
    >
      <Icon icon={icon} size='sm' color={value ? '#6298fd' : '#a0a0a0'} />
      <span>{helpText}</span>
    </CategoryButton>
  );
};

Category.propTypes = {
  helpText: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  icon: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
};

Category.defaultProps = {
  'data-testid': 'category',
  onChange: null,
};

export default Category;
