export const types = {
  SET_VIEW: 'ALERTS_SET_VIEW',
  SET_RESEARCHER_FILTER: 'ALERTS_SET_RESEARCHER_FILTER',
  SET_RESEARCHER_TO_UNFOLLOW: 'ALERTS_SET_RESEARCHER_TO_UNFOLLOW',
  SET_ALERT_TO_DELETE: 'ALERTS_SET_ALERT_TO_DELETE',
  SET_ALERT_TO_EDIT: 'ALERTS_SET_ALERT_TO_EDIT',
  SET_ORGANISATION_FILTER: 'ALERTS_SET_ORGANISATION_FILTER',
  SET_ORGANISATION_TO_UNFOLLOW: 'ALERTS_SET_ORGANISATION_TO_UNFOLLOW',
  SET_ALERT_TO_CREATE: 'ALERTS_SET_ALERT_TO_CREATE',
  SET_ALERT_FILTER: 'SET_ALERT_FILTER',

};

export const setView = view => ({
  type: types.SET_VIEW,
  payload: { view },
});

export const setAlertFilter = alertsFilter => ({
  type: types.SET_ALERT_FILTER,
  payload: { alertsFilter },
});

export const setResearcherFilter = researcherFilter => ({
  type: types.SET_RESEARCHER_FILTER,
  payload: { researcherFilter },
});

export const setResearcherToUnfollow = researcherToUnfollow => ({
  type: types.SET_RESEARCHER_TO_UNFOLLOW,
  payload: { researcherToUnfollow },
});

export const setOrganisationFilter = organisationFilter => ({
  type: types.SET_ORGANISATION_FILTER,
  payload: { organisationFilter },
});

export const setOrganisationToUnfollow = organisationToUnfollow => ({
  type: types.SET_ORGANISATION_TO_UNFOLLOW,
  payload: { organisationToUnfollow },
});

export const setAlertToDelete = alertToDelete => ({
  type: types.SET_ALERT_TO_DELETE,
  payload: { alertToDelete },
});

export const setAlertToEdit = alertToEdit => ({
  type: types.SET_ALERT_TO_EDIT,
  payload: { alertToEdit },
});

export const setAlertToCreate = alertToCreate => ({
  type: types.SET_ALERT_TO_CREATE,
  payload: { alertToCreate },
});
