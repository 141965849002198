import { useSelector } from 'react-redux';

import { getActiveFilters } from 'selectors/search';
import {
  phases,
  statuses,
  roles,
  sponsorsTypeahead,
  leadSponsorTypeahead,
} from 'services/inputTokens';

import { Filter } from '../helpers';
import FilterGroup from './FilterGroup';
import { trialKeys } from './keys';

const Tradeshows = () => {
  const activeFilters = useSelector(getActiveFilters(Object.keys(trialKeys)));
  const phaseFilterActive = activeFilters.includes('phase');

  return (
    <FilterGroup
      icon='trial'
      title='Clinical Trials'
      activeKeys={activeFilters}
      sectionKey='trials'
    >
      <>
        <Filter
          filterType='checkbox'
          filterId={trialKeys.hasClinicalTrials.key}
        >
          Listed on at least one clinical trial
        </Filter>
        <Filter
          filterType='token'
          placeholder='Phase'
          options={phases}
          filterId={trialKeys.phase.key}
        />
        <Filter
          disabled={!phaseFilterActive}
          filterType='datePicker'
          placeholder={['Phase Start From', 'Phase Start To']}
          type='range'
          filterId={trialKeys.phaseStartDate.key}
          horizontalTitle='Phase Start Date'
        />
        <Filter
          filterType='token'
          placeholder='Status'
          options={statuses}
          filterId={trialKeys.overallStatus.key}
        />
        <Filter
          filterType='token'
          placeholder='Lead Sponsor'
          filterId={trialKeys.leadSponsor.key}
          options={leadSponsorTypeahead}
        />
        <Filter
          filterType='token'
          placeholder='All Sponsors'
          filterId={trialKeys.sponsors.key}
          options={sponsorsTypeahead}
        />
        <Filter
          filterType='token'
          placeholder='Role'
          options={roles}
          filterId={trialKeys.roles.key}
        />
        <Filter
          filterType='datePicker'
          placeholder={['Trial Start From', 'Trial Start To']}
          type='range'
          filterId={trialKeys.startDate.key}
        />
        <Filter
          filterType='number'
          filterId={trialKeys.minSponsoredTrials.key}
          placeholder='Min. Sponsored Trials'
          min={0}
        />
      </>
    </FilterGroup>
  );
};

export default Tradeshows;
