import React, { useContext } from 'react';
import { performSearch, setHasDismissedVCFundingSortModal, setOrgSortBy, setShowVCFundingSortModal } from "actions/search";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ventureCapitalKeys } from '../Filters/keys';
import { SearchContext } from '..';
import { getFilters, getOrgSortBy, getSearchView, getShowVCFundingSortModal, hasDissmissedVCFundingSortModal } from 'selectors/search';
import { ResearcherSearch } from '../Results/helpers';

const LatestVCFundingSortBy = 'SuperOrganisationLatestFundingRound.AnnouncedDate';

export const useCanShowVCFundingSortModal = () => {
    const view = useSelector(getSearchView);
    const filters = useSelector(getFilters);
    const dissmissedVCFundingSortModal = useSelector(hasDissmissedVCFundingSortModal);
    const orgSortBy = useSelector(getOrgSortBy)
    return orgSortBy !== LatestVCFundingSortBy && !ResearcherSearch(view) && !dissmissedVCFundingSortModal && Object.keys(filters).some(key => Object.keys(ventureCapitalKeys).includes(key));
}

export default function FundingSortModal() {
    const dispatch = useDispatch();
    const { postSearch, postGeoSearch } = useContext(SearchContext);
    const showVCFundingSortModal = useSelector(getShowVCFundingSortModal);

    function okAction() {
        dispatch(setOrgSortBy(LatestVCFundingSortBy));
        onCloseAction();
    }

    function cancelAction() {
        onCloseAction();
    }

    function onCloseAction() {
        
        dispatch(setHasDismissedVCFundingSortModal(true));
        dispatch(performSearch(postSearch, postGeoSearch));
        dispatch(setShowVCFundingSortModal(false));
    }

    if (!showVCFundingSortModal) return null;

    return (
        <Modal
          open
          title='Sort By Latest Funding Round'
          okText='Yes'
          onOk={okAction}
          cancelText='No'
          onCancel={cancelAction}
        >
          Would you like to sort your search by the latest funding round?
          <br />
        </Modal>
      );
}