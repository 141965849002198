import { createSelector } from 'reselect';

export const getFilter = ({ filters }, id, group) => {
  if (group && filters[group]) {
    return filters[group][id];
  }
  return filters[id];
};

export const getSearchRequest = createSelector(
  s => s.searchRequest,
  req => ({
    res: req.res || {},
    loading: req.loading,
    error: req.error,
  })
);

export const getFilters = state => state.filters;

export const getPageSize = state => state.pageSize;

export const getPageNumber = state => state.pageNumber;

export const getSort = state => state.sort;

export const getSortDir = state => state.sortDir;

export const getLastSuccessfulQuery = state => state.lastSuccessfulSearch.query;

export const getExternalSearchPending = state => state.externalSearchPending;

export const getShowNotice = state => state.showNotice;
