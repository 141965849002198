import { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { HelpTooltip } from 'components/common';
import { appendFilter } from 'actions/search';

import { StyledTable, TableContainer } from '../styled';
import { GraphLabel } from './GraphBase/styled';

export default function TopInvestors({ data }) {
  const dispatch = useDispatch();

  const handleClick = organisationName => {
    dispatch(appendFilter('investors', organisationName));
  };

  if (!data) return null;

  const fundingOrgConfig = {
    headers: [
      {
        label: <div>Rank</div>,
        key: 'rank',
      },
      { label: 'Organisation', key: 'organisationName' },
      {
        label: <div style={{ marginLeft: 'auto' }}>Investments</div>,
        key: 'investments',
      },
    ],
    createRow: (r, i) => {
      const backgroundColor =
        r.rank % 2 !== 0 ? 'hsl(189, 48%, 55%, 0.04)' : '#ffffff';

      return (
        <Fragment key={`${r.organisation}-${i}`}>
          <div
            style={{
              backgroundColor,
              display: 'inline-block',
              cursor: 'pointer',
            }}
            onClick={() => handleClick(r.key)}
          >
            {r.rank}
          </div>
          <div
            style={{ backgroundColor, cursor: 'pointer' }}
            onClick={() => handleClick(r.key)}
          >
            {r.key}
          </div>
          <div
            style={{
              backgroundColor,
              textAlign: 'right',
              display: 'inline-block',
              cursor: 'pointer',
            }}
            onClick={() => handleClick(r.key)}
          >
            {r.value}
          </div>
        </Fragment>
      );
    },
  };

  return (
    <TableContainer>
      <GraphLabel>
        Top Investors
        <HelpTooltip>
          The top investors funding the organisations in your results. (This chart includes investments across all time)
        </HelpTooltip>
      </GraphLabel>
      <StyledTable config={fundingOrgConfig} data={data} />
    </TableContainer>
  );
}
