export const getUserSettingsFromState = {
    DisplayBoolErrorMessage: 'displayBoolErrorMessage',
    DisplayPhraseErrorMessage: 'displayPhraseErrorMessage',

}

export const getUserSettingsFromUrl = {
    CanPushBlankEmails: 'CanPushBlankEmails',
}

export const userSettingToStringMap = {
    [getUserSettingsFromUrl.CanPushBlankEmails]: 'Can Push Blank Emails',
    [getUserSettingsFromState.DisplayBoolErrorMessage]: 'Display Boolean Error Message',
    [getUserSettingsFromState.DisplayPhraseErrorMessage]: 'Display Phrase Error Message'
};

export const userSettingsValidation = {
    [getUserSettingsFromUrl.CanPushBlankEmails]: (u) => { return u.isAccountManager  && (u.canPushToSalesforce || u.canPushToHubspot) }
}

export const GetValidatedUserSettings = (userSettings, user) => {
    let retUserSettings = [];
    for (var setting in userSettings) {
        if (userSettingsValidation[setting] === undefined ||
            (userSettingsValidation[setting] !== undefined && userSettingsValidation[setting](user))) {
            retUserSettings.push(setting);
        }
    }
    return retUserSettings;
}