import { Link, Statistic } from 'components/common';
import { format } from 'utils/date';

import {
  Pane,
  Detail,
  DetailColumn,
  Row,
  Column,
  StatsColumn,
  RelatedColumn,
  Alert,
  DetailContainer,
} from './styled';
import { useAuth } from 'hooks';
import { useMemo } from 'react';
import licenseType from 'models/licenseType';

const Researchers = ({ researchers }) =>
  researchers.reduce((acc, r, i) => {
    if (i === researchers.length - 1) {
      if (r.id)
        return [
          ...acc,
          <Link key={r.id} inline to={`/researcher/${r.id}`}>
            {r.researcherFullName}
          </Link>,
        ];
      return [...acc, <span key={i}>{r.researcherFullName}</span>];
    }
    if (r.id)
      return [
        ...acc,
        <Link key={r.id} inline to={`/researcher/${r.id}`}>
          {r.researcherFullName}
        </Link>,
        <span key={`comma${i}`}>, </span>,
      ];
    return [
      ...acc,
      <span key={i}>{r.researcherFullName}</span>,
      <span key={`comma${i}`}>, </span>,
    ];
  }, []);

const Collaborators = ({ collaborators }) =>
  collaborators.reduce((acc, c, i) => {
    var splitCharacter = i === collaborators.length - 1 ? '' : ', ';

    if (c.superOrgId) {
      return [
        ...acc,
        <Link to={`/organisation/${c.superOrgId}`}>
          {c.name}
          {splitCharacter}
        </Link>,
      ];
    }
    return [
      ...acc,
      <span key={c.name}>
        {c.name}
        {splitCharacter}
      </span>,
    ];
  }, []);

const Details = ({
  study,
  researchers,
  relatedPublications,
  relatedGrants,
  relatedPublicationsCount,
  relatedGrantsCount,
  studyKeywordsCount,
  studyMeshTermsTotal,
}) => {
  const { hasLicense } = useAuth();
  const hasIndustryLicense = useMemo(
    () => hasLicense([licenseType.industry], true),
    [hasLicense]
  );

  return (
    <Row>
      <Column>
        <Pane title='Trial Details' id='details'>
          <Detail label='Title'>{study.briefTitle}</Detail>
          <DetailContainer>
            <DetailColumn>
              <Detail label='Identifier'>{study.nctId}</Detail>
              {hasIndustryLicense && study.sponsorSuperOrg && (
                <Detail label='Sponsor'>
                  <Link to={`/organisation/${study.sponsorSuperOrg}`}>
                    {study.sponsor}
                  </Link>
                </Detail>
              )}
              {(!hasIndustryLicense || !study.sponsorSuperOrg) && (
                <Detail label='Sponsor'>{study.sponsor}</Detail>
              )}
              <Detail label='Collaborators'>
                {study.collaborators.length && (
                  <Collaborators collaborators={study.collaborators} />
                )}
              </Detail>
              <Detail label='Responsible Party'>
                {study.responsibleParty}
              </Detail>
              <Detail label='Researchers'>
                <Researchers researchers={researchers} />
              </Detail>
            </DetailColumn>
            <DetailColumn>
              <Detail label='Recruitment Status'>{study.overallStatus}</Detail>
              <Detail label='Phase'>{study.phase}</Detail>
              <Detail label='First Posted'>
                {format(study.studyFirstPostedDate)}
              </Detail>
              <Detail label='Last Update Posted'>
                {format(study.lastUpdatePostedDate)}
              </Detail>
            </DetailColumn>
          </DetailContainer>
        </Pane>
      </Column>
      <StatsColumn>
        <Statistic
          title='Publications'
          icon='book'
          value={relatedPublicationsCount}
        />
        <Statistic title='Grants' icon='award' value={relatedGrantsCount} />
        <Statistic
          title='Keywords'
          icon='keywords'
          value={studyKeywordsCount}
        />
        <Statistic
          title='Focused Research Areas'
          icon='mesh'
          value={studyMeshTermsTotal}
        />
      </StatsColumn>
      <RelatedColumn>
        <Pane title='Related Information' id='related'>
          <Detail label='Associated Publications'>
            {relatedPublications.length
              ? relatedPublications.map(p => (
                  <Link
                    key={p.scileadsCitationId}
                    to={`/publication/${p.scileadsCitationId}`}
                  >
                    {p.pmidTitle}
                  </Link>
                ))
              : null}
          </Detail>
          <Detail label='Associated Grants'>
            {relatedGrants.length
              ? relatedGrants.map(g => (
                  <Link key={g.id} to={`/project/${g.id}`}>
                    {g.grantIdTitle}
                  </Link>
                ))
              : null}
          </Detail>
          <Link
            to={`https://clinicaltrials.gov/ct2/show/study/${study.nctId}`}
            external
          >
            View Directly in ClinicalTrials.Gov
          </Link>
          <Alert
            type='info'
            message="Not seeing your search keyword on this page? We index the 'Results' section of Clinical Trials without displaying it on the page. View the results data and all other trial data using the link above to the clinical trial gov website."
          />
        </Pane>
      </RelatedColumn>
    </Row>
  );
};

export default Details;
