import { combineReducers } from 'redux';

import { reducer as search } from './search';
import { reducer as purchasing } from './purchasing';
import { reducer as other } from './other';
import { reducer as lists } from './lists';
import { reducer as alerts } from './alerts';
import { reducer as calendar } from './calendar';
import { reducer as organisation } from './organisation';
import { reducer as userSettings } from './userSettings';

import { reducer as users } from './users';
import { reducer as rateLimits } from './rateLimits';
import { reducer as ai } from './ai';

export default combineReducers({
  search,
  purchasing,
  other,
  lists,
  alerts,
  organisation,
  calendar,
  users,
  userSettings,
  rateLimits,
  ai,
});
