import { useSelector } from 'react-redux';
import React from 'react';

import ResearcherStatistics from './ResearcherStatistics';
import PurchasingStatistics from './PurchasingStatistics';
import { getOrganisationView } from 'selectors/organisation';
import VCFundingStats from './VCFundingStats';
import AcquisitionStats from './AcquisitionStats';
import {
  ORGANISATIONACQUISITIONSVIEW,
  ORGANISATIONINVESTMENTSVIEW,
  ORGANISATIONPURCHASINGVIEW,
  ORGANISATIONVCFUNDINGVIEW,
} from '../helpers';
import InvestmentStats from './InvestmentStats';

const Statistics = ({ organisation, loading }) => {
  const view = useSelector(getOrganisationView);

  let stats;

  if (view === ORGANISATIONPURCHASINGVIEW) {
    stats = (
      <PurchasingStatistics organisation={organisation} loading={loading} />
    );
  } else if (view === ORGANISATIONVCFUNDINGVIEW) {
    stats = <VCFundingStats organisation={organisation} loading={loading} />;
  } else if (view === ORGANISATIONACQUISITIONSVIEW) {
    stats = <AcquisitionStats organisation={organisation} loading={loading} />;
  } else if (view === ORGANISATIONINVESTMENTSVIEW) {
    stats = <InvestmentStats organisation={organisation} loading={loading} />;
  } else {
    stats = (
      <ResearcherStatistics organisation={organisation} loading={loading} />
    );
  }

  return stats;
};

export default Statistics;
