import { Button, Icon, Loader, Radio } from '../../common';
import { Pane } from './styled';
import { Modal, Tooltip } from 'antd';
import { colours } from '../../../utils/theme';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { StyledTable } from '../../Alerts/styled';
import styled from 'styled-components';
import Graphs from '../Graphs';

const TopCategoriesHelpText = () => (
  <div>
    <p>
      These are top level fields determined by the Journals that a researcher
      publishes in and help highlight their general work area. They are a great
      way to get a top level understanding of their research.
    </p>
  </div>
);

const TableRow = styled.div`
  padding: 5px 10px !important;
  font-size: 14px;
`;

const CountRow = styled(TableRow)`
  justify-content: flex-end;
`;

const TopCategoriesActions = ({ loading, toggleModal }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        size='small'
        disabled={loading}
        onClick={toggleModal}
        style={{ marginRight: 10 }}
      >
        Expand
      </Button>
      <Tooltip title={TopCategoriesHelpText} placement='left'>
        <div style={{ marginTop: '-2px', cursor: 'pointer' }}>
          <Icon icon='help' color={colours.teal} size='xs' />
        </div>
      </Tooltip>
    </div>
  );
};

const tableConfig = {
  headers: [
    { label: ' ', key: 'name', hidden: true },
    { label: ' ', key: 'count', hidden: true },
  ],
  createRow: r => (
    <Fragment key={r.id}>
      <TableRow>{r.name}</TableRow>
      <CountRow>
        <span>{r.count}</span>
      </CountRow>
    </Fragment>
  ),
};

const TopCategories = ({ loading, categories, researcher }) => {
  const viewOptions = useMemo(
    () => [
      {
        label: '3 Years',
        value: 36,
      },
      {
        label: 'All Time',
        value: 0,
      },
    ],
    []
  );

  const totalCount = 20;
  const minCount = 5;

  const [showTopCategoriesModal, setShowTopCategoriesModal] = useState(false);
  const [selectedView, setSelectedView] = useState(viewOptions[0].value);
  const [topCategories, setTopCategories] = useState({});
  const [summary, setSummary] = useState(null);
  const toggleTopCategoriesModal = () => setShowTopCategoriesModal(s => !s);

  const calculateSummary = useCallback(
    allCategoryData => {
      const colors = {
        0: 'hsla(15, 100%, 59%, 0.4)',
        36: 'hsl(189, 48%, 55%, 0.4)',
      };
      let labels = [];
      let datasets = [];

      //selected view labels first
      allCategoryData[selectedView].forEach(cat => labels.push(cat.name));

      Object.keys(allCategoryData)
        .filter(key => key !== selectedView)
        .forEach(key => {
          allCategoryData[key].forEach(cat => {
            if (!labels.includes(cat.name) && labels.length < totalCount) {
              labels.push(cat.name);
            }
          });
        });

      viewOptions.forEach(view => {
        const data = [];
        labels.forEach(label => {
          const category = allCategoryData[view.value].find(
            c => c.name === label
          );
          if (category) {
            data.push(category.count);
          } else {
            data.push(0);
          }
        });
        datasets.push({
          stack: view.label,
          label: view.label,
          backgroundColor: colors[view.value],
          data: data,
        });
      });

      setSummary({
        datasets,
        labels,
      });
    },
    [selectedView, viewOptions]
  );

  useEffect(() => {
    if (categories[selectedView]) {
      let allCategoryData = {};
      viewOptions.forEach(view => {
        const allCat = [];
        Object.keys(categories[view.value]).forEach(c => {
          allCat.push({ name: c, count: categories[view.value][c] });
        });
        allCategoryData[view.value] = allCat.sort((a, b) => a - b);
      });
      setTopCategories(allCategoryData);
      calculateSummary(allCategoryData);
    }
  }, [calculateSummary, selectedView, categories, viewOptions]);

  if (
    !loading &&
    Object.keys(categories).every(k => Object.keys(categories[k]).length <= 0)
  ) {
    return null;
  }

  return (
    <Pane
      title={`Broad Research Categories`}
      headerRight={
        <TopCategoriesActions
          loading={loading}
          toggleModal={toggleTopCategoriesModal}
        />
      }
    >
      {loading && <Loader />}
      {!loading && topCategories[selectedView] && (
        <div>
          <Radio
            disabled={loading}
            value={selectedView}
            options={viewOptions}
            optionType='button'
            onChange={v => setSelectedView(v)}
            style={{ fontWeight: 'normal', marginTop: 5, marginBottom: 5 }}
            size='small'
          />
          <StyledTable
            config={tableConfig}
            data={topCategories[selectedView].slice(0, minCount)}
            hideHeader={true}
          />
        </div>
      )}

      {showTopCategoriesModal && (
        <Modal
          open={showTopCategoriesModal}
          title={`Broad Research Categories for ${researcher?.researcherProfile?.researcherFullName}`}
          width='75vw'
          style={{ top: 30 }}
          okText='Close'
          onOk={toggleTopCategoriesModal}
          onCancel={toggleTopCategoriesModal}
          cancelText={null}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <Radio
            disabled={loading}
            value={selectedView}
            options={viewOptions}
            optionType='button'
            onChange={v => setSelectedView(v)}
            style={{ fontWeight: 'normal', marginTop: 5, marginBottom: 5 }}
            size='small'
          />
          <Graphs
            height={'500px'}
            summaryData={summary}
            showLegend={false}
            type='horizontalBar'
            labelLength='35'
          />
        </Modal>
      )}
    </Pane>
  );
};

export default TopCategories;
