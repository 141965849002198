import { Tooltip } from 'components/common';
import { Filter, helpText } from '../helpers';
import { useSelector } from 'react-redux';
import { getFilter } from 'selectors/search';
import { tradeshowOrganisationKeys } from './keys';

const OrgFilters = () => {
  const tradeshowValue = useSelector(
    getFilter(tradeshowOrganisationKeys.tradeshow.key)
  );
  const thisShowSelected = useSelector(
    getFilter(tradeshowOrganisationKeys.thisShowOnly.key)
  );

  return (
    <>
      <Tooltip
        title={helpText.exhibitingShow}
        placement='right'
        overlayStyle={{ maxWidth: 450 }}
      >
        <Filter
          filterType='checkbox'
          filterId={tradeshowOrganisationKeys.exhibitingShow.key}
          disabled={
            !tradeshowValue || tradeshowValue.length === 0 || thisShowSelected
          }
        >
          Exhibiting at a tradeshow
        </Filter>
      </Tooltip>
      <Tooltip
        title={helpText.presentingShow}
        placement='right'
        overlayStyle={{ maxWidth: 450 }}
      >
        <Filter
          filterType='checkbox'
          filterId={tradeshowOrganisationKeys.presentingAtTradeshow.key}
          disabled={
            !tradeshowValue || tradeshowValue.length === 0 || thisShowSelected
          }
        >
          Presenting at a tradeshow
        </Filter>
      </Tooltip>
    </>
  );
};

export default OrgFilters;
